import { OrderRecordContext } from '_contexts/OrderRecordContext'
import {
  HOST_ORDER_CANCELED,
  HOST_ORDER_EVENT_COLLECTED,
  HOST_ORDER_EVENT_VERIFIED,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_PAID,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
} from '_utils/constant'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Select from 'react-select'

const slateColor = '#64748b'

function OrderStatus() {
  const { onChangeOrderStatus, filter, tabOrderEvent } = useContext(OrderRecordContext)
  const { orderStatus } = filter

  const optionsOrderShop = [
    { value: HOST_ORDER_UNPAID, label: 'Unpaid' },
    { value: HOST_ORDER_PAID, label: 'Paid' },
    { value: HOST_ORDER_VERIFIED, label: 'Verified' },
    { value: HOST_ORDER_CANCELED, label: 'Cancelled' },
    { value: HOST_ORDER_FULFILLED, label: 'Fulfilled' },
  ]

  const optionsOrderEvent = [
    { value: HOST_ORDER_CANCELED, label: 'Cancelled' },
    { value: HOST_ORDER_UNPAID, label: 'Unpaid' },
    { value: HOST_ORDER_EVENT_VERIFIED, label: 'Verified' },
    { value: HOST_ORDER_EVENT_COLLECTED, label: 'Collected' },
  ]

  const currentOptions = tabOrderEvent === null ? optionsOrderShop : optionsOrderEvent

  const customStyles = {
    control: (provided, { hasValue }) => ({
      ...provided,
      minHeight: '34px',
      height: hasValue ? 'auto' : '34px',
      backgroundColor: 'white',
      borderRadius: '0.25rem',
      borderColor: slateColor,
      boxShadow: 'none',
      transition: 'all 0.15s ease-in-out',
      marginRight: '1rem',
      '&:hover': {
        borderColor: slateColor,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      borderRadius: '0.25rem',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.75rem',
      padding: '0.5rem 1rem',
      backgroundColor: state.isSelected
        ? '#0badad'
        : state.isFocused
        ? 'rgba(11, 173, 173, 0.1)'
        : 'white',
      color: state.isSelected ? 'white' : '#64748b',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: 'rgba(11, 173, 173, 0.2)',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(11, 173, 173, 0.1)',
      borderRadius: '0.25rem',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      fontWeight: 500,
      color: '#0badad',
      padding: '0.25rem',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#0badad',
      '&:hover': {
        backgroundColor: '#0badad',
        color: 'white',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      color: '#64748b',
      fontWeight: 500,
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '0.75rem',
      color: '#64748b',
      fontWeight: 500,
    }),
    valueContainer: (provided, { hasValue }) => ({
      ...provided,
      height: hasValue ? 'auto' : '34px',
    }),
  }

  return (
    <div className='select-status w-100'>
      <Select
        isMulti
        name='colors'
        options={currentOptions}
        value={orderStatus}
        onChange={onChangeOrderStatus}
        className='basic-multi-select cursor-pointer'
        classNamePrefix='select'
        placeholder='Order Status'
        styles={customStyles}
      />
    </div>
  )
}

OrderStatus.propTypes = {
  getOrderStatus: PropTypes.func,
  orderStatus: PropTypes.array,
}

export default OrderStatus
