import { useDeliveryZonesLogic } from '_hooks/useDeliveryZonesLogic'
import classNames from 'classnames'
import React, { useRef } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { FaTrashAlt } from 'react-icons/fa'
import { IoAddOutline, IoSave } from 'react-icons/io5'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import CustomButton from '../CustomButton'
import DatePicker from './DatePicker'
import DeliveryFee from './DeliveryFee'
import DeliveryOptions from './DeliveryOptions'
import OrderActions from './OrderActions'
import './style.scss'
import TimePicker from './TimePicker'

export default function ModalDeliveryZones({
  isOpen,
  toggle,
  dataModal,
  setDataModal,
  listDelivery,
  ...args
}) {
  const datePickerPortalRef = useRef(null)

  const {
    onDeleteDeliveryZone,
    onUpdateStatusDelivery,
    onExportOrder,
    isOpenOrder,
    handleSubmit,
    onSubmit,
    control,
    reset,
    register,
    errors,
    setValue,
    selectedDeliveryZones,
    isSubmitting,
    onSelectDeliveryZone,
    onChangeDate,
    onChangeTime,
  } = useDeliveryZonesLogic(isOpen, dataModal, setDataModal, toggle)

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-delivery-zones' {...args}>
      <ModalHeader className='text-orange' toggle={toggle}>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <span>{!dataModal ? 'Add zones to Co-Bee' : 'Edit zones to Co-Bee'}</span>
        </div>
      </ModalHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...{ control, reset, register, errors, setValue, selectedDeliveryZones }}>
          <ModalBody className='modal-body custom-scrollbar pb-0'>
            <DeliveryOptions control={control} onSelectDeliveryZone={onSelectDeliveryZone} />
            <DatePicker onChangeDate={onChangeDate} popperContainerRef={datePickerPortalRef} />
            <Controller
              name='time'
              control={control}
              render={({ field }) => <TimePicker time={field.value} onChangeTime={onChangeTime} />}
            />

            <DeliveryFee />
          </ModalBody>
          <ModalFooter className='d-flex justify-content-between'>
            {dataModal && (
              <CustomButton
                onClick={onDeleteDeliveryZone}
                icon={<FaTrashAlt />}
                variant='outlined'
                color='slate'
                className='button-delete mt-0 w-auto border-0'
              />
            )}
            {dataModal && (
              <OrderActions
                toggleOrder={onUpdateStatusDelivery}
                exportOrders={onExportOrder}
                isOpenOrder={isOpenOrder}
              />
            )}
            <CustomButton
              type='submit'
              color='aqua'
              variant='contained'
              icon={dataModal ? <IoSave /> : <IoAddOutline />}
              className={classNames('mt-0 w-auto', { 'ms-auto': !dataModal })}
              isDisabled={isSubmitting}
              text={
                !dataModal
                  ? isSubmitting
                    ? 'Creating...'
                    : 'Create'
                  : isSubmitting
                  ? 'Updating...'
                  : 'Update'
              }
            />
          </ModalFooter>
        </FormProvider>

        {/* popup calendar */}
        <div ref={datePickerPortalRef}></div>
      </form>
    </Modal>
  )
}
