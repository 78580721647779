import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import CustomButton from '_components/CustomButton'
import cancelImage from '_images/cancel.png'
import noImage from '_images/noimage02.svg'
import { DEFAULT_PRODUCT_EVENT } from '_utils/constant'
import { getCreateEventData, setCreateEventData } from '_utils/localData'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoArrowBack, IoSave } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'
import ProductList from '../components/ProductList'
import './style.scss'

// const validatePrice = (value, options = { minValue: 0 }) => {
//   if (!value) return false
//   const normalizedValue = value.replace(/[$,]/g, '')
//   return (
//     /^\d+(\.\d{0,2})?$/.test(normalizedValue) && parseFloat(normalizedValue) >= options.minValue
//   )
// }

const validationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      pid: Yup.string(),
      name: Yup.string().required('Product name is required'),
      description: Yup.string().nullable().notRequired(),
      price: Yup.number()
        .typeError('Amount must be a number')
        .required('Please provide plan cost.'),
      maxQuantity: Yup.number()
        .typeError('Available stock is required')
        .required('Available stock is required'),
      defaultQuantity: Yup.number()
        .typeError('Default quantity is required')
        .default(0)
        .required('Default quantity is required'),
      limitPerOrder: Yup.number()
        .typeError('Limit per order is required')
        .required('Limit per order is required'),
      minOrderQty: Yup.number()
        .typeError('Minimum order quantity must be greater than 0')
        .min(1, 'Minimum order quantity must be greater than 0')
        .required('Minimum order quantity must not be blank.'),
      photoUrls: Yup.array().notRequired().nullable(),
      subItems: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required('Sub item name is required'),
          required: Yup.boolean().default(false),
          list: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Option name is required'),
              price: Yup.string()
                .required('Price is required')
                // .test('is-valid-price', 'Price must be greater than 0', (value) =>
                //   validatePrice(value, { minValue: 0.01 })
                // )
                .transform((value) => {
                  if (!value) return value
                  return value.replace(/[$,]/g, '')
                }),
            })
          ),
        })
      ),
    })
  ),
})

const EventCreateItem = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const createEventData = getCreateEventData()
  const { selectedShop } = useSelector((state) => state.shop)

  const idParam = new URLSearchParams(window.location.search).get('id')

  useEffect(() => {
    if (selectedShop?.id && !createEventData && !idParam) {
      history.push(`/admin-console/${selectedShop?.shopSlug}/event/create`)
    }
  }, [selectedShop?.id, idParam, JSON.stringify(createEventData)])

  const [listImageEvent, setListImageEvent] = useState(
    createEventData?.photoUrls.map((item) => ({ url: item, file: '' } || [])) || []
  )

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    defaultValues: createEventData
      ? {
          ...createEventData,
          products: createEventData.products.map((product) => ({
            ...product,
            // price: product.price?.toString().replace('.', ','), // Format lại giá tiền từ dấu . thành dấu ,
            description: product.description || '', // Ensure description is initialized
          })),
        }
      : {
          products: [{ ...DEFAULT_PRODUCT_EVENT, description: '' }], // Initialize with empty description
        },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  })

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files.length + listImageEvent.length > 5) {
      addToast('Maximum images for event is 5!', { appearance: 'warning', autoDismiss: true })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (i < 5) {
        listImageEvent.push({ url: URL.createObjectURL(files[i]), file: files[i] })
      }
    }
    setListImageEvent([...listImageEvent])
  }

  const handleOnRemoveImg = (index) => {
    listImageEvent.splice(index, 1)
    setListImageEvent([...listImageEvent])
  }

  const onSubmit = async (_data) => {
    try {
      // Format products data before saving
      _data.products = _data.products?.map((product) => ({
        ...product,
        price: product.price,
        minOrderQty: product?.minOrderQty ? product?.minOrderQty : 1,
        description: product.description || '', // Ensure description is always defined
        subItems: product.subItems.map(({ id, list, ...subItem }) => ({
          ...subItem,
          list: list.map((item) => ({
            ...item,
            price: parseFloat(item.price),
          })),
        })),
      }))

      const list = listImageEvent
      for (let i = 0; i < list.length; i++) {
        const element = list[i]
        if (element.file) {
          const { msgResp } = await uploadApi.uploadPhoto([element.file])
          element.url = msgResp.url
        }
      }
      _data.photoUrls = list.map((item) => item.url)

      if (_data?.products?.length === 0) {
        addToast('You must have at least 1 product!!!', { appearance: 'error', autoDismiss: true })
        return
      }

      setCreateEventData({
        ..._data,
        shopId: selectedShop?.id,
      })

      if (idParam) {
        history.push(`/admin-console/${selectedShop?.shopSlug}/event/update-confirm?id=${idParam}`)
      } else {
        history.push(`/admin-console/${selectedShop?.shopSlug}/event/created-confirm`)
      }
    } catch (error) {
      addToast('Error saving data', { appearance: 'error', autoDismiss: true })
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='create-item container'>
      <h4 className='create-item__title text-center w-100 mb-3'>Banner</h4>
      <div className='create-item__header'>
        <div className='create-item__image-info ms-auto'>
          <span className='create-item__image-remain'>
            Remain: ({5 - listImageEvent.length} photos left)
          </span>
        </div>
      </div>

      <div className='create-item__upload'>
        <div className='create-item__upload-placeholder'>
          <img src={noImage} alt='No Image' className='create-item__upload-placeholder-image' />
          <div className='create-item__upload-text'>
            <span>Upload Image</span>
          </div>
          <div className='create-item__upload-input'>
            <input type='file' accept='image/*' multiple onChange={onUserImageUploaded} />
          </div>
        </div>
      </div>

      <h4 className='create-item__subtitle'>Items for Sales</h4>

      {listImageEvent.length > 0 && (
        <div className='create-item__images'>
          {listImageEvent.map((item, index) => (
            <div key={index} className='create-item__image'>
              <img src={item.url} alt='' className='create-item__image-main' />
              <img
                src={cancelImage}
                className='create-item__image-cancel'
                onClick={() => handleOnRemoveImg(index)}
              />
            </div>
          ))}
        </div>
      )}

      <ProductList {...{ control, register, getValues, setValue, errors, watch }} />

      <div className='create-item__footer-buttons'>
        <CustomButton
          text='Previous'
          icon={<IoArrowBack />}
          color='slate'
          onClick={() => {
            setCreateEventData(getValues())
            if (createEventData?.id || idParam) {
              history.push(
                `/admin-console/${selectedShop?.shopSlug}/event/update?id=${
                  createEventData?.id || idParam
                }`
              )
            } else {
              history.push(`/admin-console/${selectedShop?.shopSlug}/event/create`)
            }
          }}
        />
        <CustomButton
          text={isSubmitting ? 'Processing...' : 'Save & Next'}
          icon={<IoSave />}
          color='aqua'
          variant='contained'
          type='submit'
          isDisabled={isSubmitting}
          // onClick={() => {
          //   if (errors?.products?.length > 0) {
          //     return addToast('Please fill out all required fields', {
          //       appearance: 'error',
          //       autoDismiss: true,
          //     })
          //   }
          // }}
        />
      </div>
    </form>
  )
}

export default EventCreateItem
