/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import useQuery from '_hooks/useQuery'
import {
  fetchListOrderLazada,
  getListOrdersByHost,
  selectTabOrderPlatform,
} from '_redux/modules/order'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_REFUNDED,
  HOST_ORDER_CANCELED,
  NO_FULFILLMENT,
  ORDER_CO_KII,
  ORDER_LAZADA,
  PLATFORM_CO_KII,
} from '_utils/constant'
import { exportToCsv, normalizeName } from '_utils/function'
import { convertStatusNumberToText } from '_utils/functions/converter'
import { handleDataOrderDigistall, handleDataOrderEvent } from '_utils/functions/handler'
import { REVERSE_ORDER_FOR_SELLER_BUTTON } from '_utils/LazadaConstants'
import { isNaN } from 'lodash'
import moment from 'moment'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { clearParamUrl } from '../utils/function'

const contextDefaultValues = {
  searchString: '',
  sorting: {},
  setSorting: () => {},
}

export const OrderRecordContext = createContext(contextDefaultValues)

export const OrderRecordProvider = ({ children }) => {
  const query = useQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const eidParam = query.get('eid')

  const [filter, setFilter] = useState({
    orderDate: [
      {
        startDate: moment().subtract(1, 'month').startOf('day').toDate(),
        endDate: moment().add(1, 'month').startOf('day').toDate(),
        key: 'selection',
      },
    ],
    orderStatus: [],
    fulfillment: [],
    fulfillmentDate: [
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ],
  })
  const [searchString, setSearchString] = useState('')
  const [sorting, setSorting] = useState({ field: 'shopOrderNo', order: 'asc', type: 'number' })
  const [isLoading, setIsLoading] = useState(false)
  const [selectFilterOrder, setSelectFilterOrder] = useState('order')
  const [activeTabReverseOrder, setActiveTabReverseOrder] = useState(
    REVERSE_ORDER_FOR_SELLER_BUTTON
  )

  const { selectedShop } = useSelector((state) => state.shop)
  const { eventsByShop } = useSelector((state) => state.event)
  const {
    listOrdersByHost,
    listDateIsPicked,
    tabOrderPlatform: tabOrder,
  } = useSelector((state) => state.order)
  const { eventAndOrderById } = useSelector((state) => state.orderEvent)

  const listOrderEvent = eventAndOrderById.find((event) => event.id === eidParam)?.orders || []

  const eventsByShopList = eventsByShop[selectedShop?.id] || []
  const eventDetail = eventsByShopList.find((event) => event.id === eidParam)

  const [tabOrderPlatform, setTabOrderPlatform] = useState(!eidParam ? tabOrder : null)
  const [tabOrderEvent, setTabOrderEvent] = useState(null)

  const { listOrder = [] } = handleDataOrderDigistall(listOrdersByHost)

  const { listOrderEventHandled = [] } = handleDataOrderEvent(
    listOrderEvent,
    eventDetail?.adminCost || 0,
    eventDetail?.discount || 0,
    eventDetail?.deliveryCost || 0
  )

  const orderStartDate = moment(filter.orderDate[0].startDate).unix()
  const orderEndDate = moment(filter.orderDate[0].endDate).unix()
  const fulfillmentStartDate = moment(filter.fulfillmentDate[0].startDate).unix()
  const fulfillmentEndDate = moment(filter.fulfillmentDate[0].endDate).unix()

  useEffect(() => {
    let isMounted = true

    const loadData = async () => {
      setIsLoading(true)
      let rangeDate = {
        startDate: orderStartDate,
        endDate: orderEndDate,
      }

      if (
        (orderStartDate === null || isNaN(orderStartDate)) &&
        (orderEndDate === null || isNaN(orderEndDate))
      ) {
        rangeDate = {
          startDate: moment().startOf('day').unix(),
          endDate: moment().startOf('day').add(30, 'days').unix(),
        }
      }

      await dispatch(getListOrdersByHost(selectedShop?.id, rangeDate))

      if (isMounted) {
        setIsLoading(false)
      }
    }

    if (
      selectedShop?.id &&
      tabOrderPlatform === ORDER_CO_KII &&
      location.pathname.includes('order-records')
    ) {
      loadData()
    }

    const loadDataLazada = async () => {
      setIsLoading(true)
      try {
        await dispatch(fetchListOrderLazada(selectedShop?.id, filter))
      } catch (error) {
        throw new Error(error.msgResp || error.message)
      } finally {
        setIsLoading(false)
      }
    }

    if (selectedShop?.id && tabOrderPlatform === ORDER_LAZADA) {
      loadDataLazada()
    }

    return () => {
      isMounted = false
    }
  }, [tabOrderPlatform, selectedShop?.id, orderStartDate, orderEndDate, location.pathname])

  const onChangeSearch = (e) => setSearchString(e.target.value)

  const clearFilter = () => {
    setSearchString('')
    setFilter({
      orderDate: [
        {
          startDate: moment().subtract(1, 'month').startOf('day').toDate(),
          endDate: moment().add(1, 'month').startOf('day').toDate(),
          key: 'selection',
        },
      ],
      orderStatus: [],
      fulfillment: [],
      fulfillmentDate: [
        {
          startDate: null,
          endDate: null,
          key: 'selection',
        },
      ],
    })

    if (query.get('startDate') && query.get('endDate')) {
      query.delete('startDate')
      query.delete('endDate')
      window.history.replaceState({}, '', `${window.location.pathname}`)
    }

    if (query.get('eid')) {
      clearParamUrl({ history, location }, [], ['eid'])
    } else {
      clearParamUrl({ history, location })
    }
  }

  const onSelectTabOrderPlatform = (tabSelected) => {
    dispatch(selectTabOrderPlatform(tabSelected))
    setTabOrderPlatform(tabSelected)
  }

  const onSelectTabEvent = (tabSelected) => setTabOrderEvent(tabSelected)

  const handleExport = (orderList, tab) => {
    const isEventOrder = tab !== PLATFORM_CO_KII
    const generalInfo = [
      ['Shop Name', selectedShop?.shopName],
      ['Shop Link', selectedShop?.shopLink],
      ['Headline', selectedShop?.headline],
      ['Shop Owner', selectedShop?.createdUserName],
      ['Contact', selectedShop?.createdUserPhone],
      [''],
      [''],
    ]

    const ordersActive = orderList.filter(
      (order) =>
        order.buyerStatus !== BUYER_ORDER_CANCELED &&
        order.buyerStatus !== BUYER_ORDER_REFUNDED &&
        order.status !== HOST_ORDER_CANCELED
    )

    const getProductNameAndSubItems = (item, subItemsKey, nameKey, quantityKey) => {
      let pNameAndSub = ''
      if (Array.isArray(item[subItemsKey]) && item[subItemsKey].length) {
        pNameAndSub += ' ('
        pNameAndSub += item[subItemsKey]
          .map((subItem) => `${subItem.name}: +$${subItem.price}`)
          .join(', ')
        pNameAndSub += ')'
      }
      return `${item[nameKey]}${item[quantityKey] ? `(qty:${item[quantityKey]})` : ''}${
        pNameAndSub ? ` ${pNameAndSub}` : ''
      }`
    }

    const mapOrderToRow = (product, subItemsKey, nameKey, quantityKey) => {
      const nameProductsOfBuyer = product?.allProductPerOrder?.map((item) =>
        getProductNameAndSubItems(item, subItemsKey, nameKey, quantityKey)
      )

      const {
        ref,
        combinedPaymentRef,
        uName,
        uPhone,
        allProductPerOrder,
        discountPerOrder,
        totalFee,
        transactionFees,
        deliveryDetail,
        pickupDetail,
        buyerStatus,
        status,
      } = product

      const roundedTotalFee = Number(totalFee.toFixed(2))

      const exportRef = tab === PLATFORM_CO_KII ? ref : combinedPaymentRef

      return [
        exportRef,
        uName,
        uPhone,
        `${allProductPerOrder?.length}`,
        nameProductsOfBuyer,
        discountPerOrder,
        `$${roundedTotalFee}`,
        transactionFees || 0,
        pickupDetail?.pickupFee || 0,
        deliveryDetail?.deliveryFee || 0,
        convertStatusNumberToText(buyerStatus, isEventOrder),
        convertStatusNumberToText(status, isEventOrder),
      ]
    }

    const rowsOfProduct = ordersActive.map((product) => {
      if (tab === PLATFORM_CO_KII) {
        return mapOrderToRow(product, 'subItems', 'name', 'quantity')
      }
      return mapOrderToRow(product, 'pSubItems', 'pName', 'pQuantity')
    })

    const rows = [
      ...generalInfo,
      [
        'Ref',
        'Name',
        'Phone',
        'Quantity Product',
        'Product',
        'Discount',
        'Total Amount',
        'Transaction Fee',
        'Pickup Fee',
        'Delivery Fee',
        'Payment',
        'Status',
      ],
      ...rowsOfProduct,
    ]

    const fileName = normalizeName(
      `${selectedShop?.shopName} shop exportOrders by ${moment().format('DD-MM-YYYY')}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const onChangeFulfillment = (fulfillment) => setFilter({ ...filter, fulfillment })

  const onSelectRangeFulfillDate = (fulfillmentDate) => setFilter({ ...filter, fulfillmentDate })

  const onSelectRangeOrderDate = (orderDate) => setFilter({ ...filter, orderDate })

  const onChangeOrderStatus = (orderStatus) => setFilter({ ...filter, orderStatus })

  const listOrdersFilter = useMemo(() => {
    const listValueFulfillment = filter.fulfillment.map((item) => item.value)
    const listValueOrderStatus = filter.orderStatus.map((item) => item.value)

    const isOrderInRange = (timestamp) =>
      moment
        .unix(timestamp)
        .isBetween(moment.unix(fulfillmentStartDate), moment.unix(fulfillmentEndDate), null, '[]')

    const filters = [
      // Filter by fulfillment
      (order) =>
        !listValueFulfillment.length ||
        listValueFulfillment.some(
          (fulfillmentItem) =>
            fulfillmentItem === order?.deliveryDetail?.deliveryOptionId ||
            fulfillmentItem === order?.pickupDetail?.pickupOptionId ||
            fulfillmentItem === order.fulfillmentType
        ),

      // Filter by order status
      (order) => !listValueOrderStatus.length || listValueOrderStatus.includes(order.status),

      // Filter by fulfillment date range
      (order) =>
        fulfillmentStartDate === null ||
        isNaN(fulfillmentStartDate) ||
        fulfillmentEndDate === null ||
        isNaN(fulfillmentEndDate) ||
        isOrderInRange(order?.pickupDetail?.pickupBookingTimestamp) ||
        isOrderInRange(order?.deliveryDetail?.deliveryTimestamp) ||
        (order.fulfillmentType === NO_FULFILLMENT && isOrderInRange(order?.createdAt)),
    ]

    return listOrder.filter((order) => filters.every((filterFn) => filterFn(order)))
  }, [
    JSON.stringify(listOrder),
    JSON.stringify(filter),
    JSON.stringify(listDateIsPicked),
    fulfillmentStartDate,
    fulfillmentEndDate,
  ])

  const listOrdersEventFiltered = useMemo(() => {
    const listValueFulfillment = new Set(filter.fulfillment.map((item) => item.value))
    const listValueOrderStatus = new Set(filter.orderStatus.map((item) => item.value))

    const isOrderInRange = (timestamp, _startDate, _endDate) =>
      moment.unix(timestamp).isBetween(moment.unix(_startDate), moment.unix(_endDate), null, '[]')

    const filters = [
      (order) => !listValueOrderStatus.size || listValueOrderStatus.has(order.status),
      (order) =>
        !listValueFulfillment.size ||
        listValueFulfillment.has(order?.deliveryDetail?.deliveryOptionId) ||
        listValueFulfillment.has(order?.pickupDetail?.pickupOptionId) ||
        listValueFulfillment.has(order.fulfillmentType),
      (order) =>
        (!fulfillmentStartDate ||
          !fulfillmentEndDate ||
          isOrderInRange(order?.ePickupTimestamp, fulfillmentStartDate, fulfillmentEndDate)) &&
        (!orderStartDate ||
          !orderEndDate ||
          isOrderInRange(order?.createdAt, orderStartDate, orderEndDate)),
      (order) =>
        !searchString ||
        order?.uPhone?.includes(searchString.toLowerCase()) ||
        order?.ref?.includes(searchString.toLowerCase()),
    ]

    return listOrderEventHandled.filter((order) => filters.every((filterFunc) => filterFunc(order)))
  }, [
    JSON.stringify(listOrderEventHandled),
    fulfillmentStartDate,
    fulfillmentEndDate,
    orderStartDate,
    orderEndDate,
    JSON.stringify(filter.fulfillment),
    JSON.stringify(filter.orderStatus),
  ])

  const sortListOrders = useMemo(() => {
    if (listDateIsPicked?.length > 0) {
      const newOrderList = listDateIsPicked.flatMap((pickedDate) => {
        const pickedDateFormatted = moment.unix(pickedDate.createdAt).format('DD-MM-YYYY')

        return listOrdersFilter.filter((item) => {
          const itemDateFormatted = moment.unix(item.createdAt).format('DD-MM-YYYY')
          return itemDateFormatted === pickedDateFormatted
        })
      })
      return newOrderList.flat()
    }

    if (listOrdersFilter) {
      let listOrderSort = [...listOrdersFilter]
      if (searchString) {
        listOrderSort = listOrderSort.filter((item) =>
          Object.values(item).some((value) =>
            value?.toString()?.toLowerCase()?.includes(searchString.toLowerCase())
          )
        )
      }
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1
        if (sorting.type === 'string') {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * (a[sorting.field]?.localeCompare(b[sorting.field]) || 0)
          )
        } else {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * ((a[sorting.field] || 0) - (b[sorting.field] || 0))
          )
        }
      }
      return listOrderSort
    }
  }, [searchString, sorting, JSON.stringify(listOrdersFilter), JSON.stringify(listDateIsPicked)])

  const sharedValues = {
    tabOrderEvent,
    onSelectTabEvent,
    filter,
    setFilter,
    searchString,
    sorting,
    setSorting,
    onChangeSearch,
    clearFilter,
    tabOrderPlatform,
    onSelectTabOrderPlatform,
    handleExport,
    onChangeFulfillment,
    onSelectRangeFulfillDate,
    onSelectRangeOrderDate,
    onChangeOrderStatus,
    sortListOrders,
    isLoading,
    setSelectFilterOrder,
    selectFilterOrder,
    setActiveTabReverseOrder,
    activeTabReverseOrder,
    listOrdersEventFiltered,
  }

  return <OrderRecordContext.Provider value={sharedValues}>{children}</OrderRecordContext.Provider>
}
