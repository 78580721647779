/* eslint-disable react/display-name */
import { useWindowSize } from '@react-hook/window-size'
import ButtonWhatsApp from '_components/ButtonWhatsApp'
import { CollapseContext } from '_contexts/CollapseMenuContext'
import { PACKAGE_SUBSCRIPTION_FOUNDER, SUBSCRIPTION_STATUS_APPROVED } from '_utils/constant'
import React, { useContext, useEffect, useState } from 'react'

import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { clearDelivery, clearPickup } from '../redux/modules/fulfillment'
import AdminConsoleSkeleton from './components/AdminConsoleSkeleton'
import Sidebar from './components/Sidebar'
import {
  CalendarSkeleton,
  MenuItemSkeleton,
  SelectBoxSkeleton,
} from './components/Sidebar/SidebarSkeleton'
import './main.scss'

function MainDigistallDesktop({
  loading,
  listRoute,
  listMenuItem,
  onChangeShop,
  typePage,
  isShowFulfillmentTag,
}) {
  const dispatch = useDispatch()
  const [width] = useWindowSize()
  const { pathname } = useLocation()
  const isMobile = width <= 991

  const { isCollapse, toggleCollapse } = useContext(CollapseContext)

  const [activeTab, setActiveTab] = useState('')

  const { currentPackage } = useSelector((state) => state.subscription)

  useEffect(() => {
    let tab = pathname.split('/').pop()
    const arrAccountPath = ['my-shops', 'change-password', 'update', 'profile']

    if (arrAccountPath.includes(tab)) tab = 'account'

    setActiveTab(tab)

    return () => {
      setActiveTab('')
      dispatch(clearPickup)
      dispatch(clearDelivery)
    }
  }, [pathname])

  if (loading) {
    return (
      <div className={`container-fluid admin-console ${isMobile ? 'mobile' : ''}`}>
        <div className='main-layout'>
          <div
            className={`sidebar d-none d-lg-block ${isCollapse ? 'sidebar--collapsed' : ''} ${
              loading ? 'loading' : ''
            }`}
          >
            <div className='d-flex justify-content-between' style={{ paddingTop: 33 }}>
              <div>
                <SelectBoxSkeleton />
                <ul className={`${!isCollapse ? 'px-2' : 'ps-4'} list-menu pt-2 mb-0`}>
                  {Array(6)
                    .fill(0)
                    .map((_, index) => (
                      <MenuItemSkeleton key={index} isCollapse={isCollapse} />
                    ))}
                </ul>
              </div>

              <div className='style-menu' onClick={toggleCollapse}>
                <div className='group'>
                  <span className='text-uppercase'>menu</span>
                  <span className='d-inline-block'>
                    {isCollapse ? <BsFillCaretRightFill /> : <BsFillCaretLeftFill />}
                  </span>
                </div>
              </div>
            </div>

            {/* fulfillment calendar */}
            <div className='d-flex pt-3'>
              <div className='prev-style-menu'>
                <span className='text-uppercase'>
                  {pathname.includes('order-records') ? 'Order Dates' : 'Fulfillment'}
                </span>
              </div>
              <div className='w-75 ms-1'>
                <CalendarSkeleton />
              </div>
            </div>
          </div>
          <div
            className={`main-content admin-console ${isCollapse ? 'main-content--collapsed' : ''} ${
              isMobile ? 'mobile' : ''
            }`}
          >
            <AdminConsoleSkeleton />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`container-fluid admin-console ${isMobile ? 'mobile' : ''}`}>
      <div className='main-layout'>
        <div className={`sidebar d-none d-lg-block ${isCollapse ? 'sidebar--collapsed' : ''}`}>
          <Sidebar
            toggleSidebar={() => {}}
            listMenuItem={listMenuItem}
            onChangeShop={onChangeShop}
            typePage={typePage}
            isShowFulfillmentTag={isShowFulfillmentTag}
          />
        </div>
        <div
          className={`main-content admin-console ${isCollapse ? 'main-content--collapsed' : ''} ${
            isMobile ? 'mobile' : ''
          }`}
        >
          <Switch>
            {listRoute.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                render={(props) => <route.component {...props} activeTab={activeTab} />}
              />
            ))}
          </Switch>

          {currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_FOUNDER &&
            currentPackage?.status === SUBSCRIPTION_STATUS_APPROVED && (
              <ButtonWhatsApp
                className={'btn-whatsapp'}
                style={{
                  position: 'fixed',
                  bottom: '1rem',
                  right: '1rem',
                  zIndex: 1000,
                }}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(MainDigistallDesktop)
