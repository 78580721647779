/* eslint-disable no-confusing-arrow */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import virtualShopApi from '_api/virtualShop'
import CustomButton from '_components/CustomButton'
import CustomSelectBox from '_components/CustomSelectBox'
import CustomSwalFire from '_components/CustomSwalFire'
import InputField from '_components/InputField'
import ContactInputs from '_components/Shop/ContactInputs'
import ShopName from '_components/Shop/ShopName'
import cancelImageIcon from '_images/cancelImageIcon.png'
import TelegramSVG from '_images/telegram.svg'
import {
  selectShopAndSelectShopDetail,
  updateShopInMyShops,
  updateShopName,
} from '_redux/modules/shop'
import { removeAccents, removeDuplicateHyphens } from '_utils/function'
import { domainGenerator, telegramLinkGenerator } from '_utils/functions/generator'
import { getUserInfo, setUserInfo } from '_utils/localData'
import { shopValidationSchema } from '_utils/validationSchemas'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { GrPowerReset } from 'react-icons/gr'
import { IoIosAdd } from 'react-icons/io'
import { IoSaveOutline, IoTrashOutline } from 'react-icons/io5'
import { MdAddPhotoAlternate, MdPhotoLibrary } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import { Tooltip } from 'reactstrap'
import ExternalLinkModal from './components/ExternalLinkModal'
import LogoModal from './components/LogoModal'
import ModalTelegram from './components/ModalTelegram'
import './style.scss'

// const URL =
//   /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uD7FF\uF900-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
// const shopNameRegex = /^[a-zA-Z0-9 ]*$/

const validationSchema = shopValidationSchema

function General() {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const timeZoneRef = useRef('')
  const currencyRef = useRef('')
  const history = useHistory()
  const userInfo = getUserInfo()

  const { selectedShop } = useSelector((state) => state.shop)

  const [listLinkExternal, setListLinkExternal] = useState([])
  const [isOpenModalAddLink, setIsOpenModalAddLink] = useState(false)
  const [isOpenModalAddLogo, setIsOpenModalAddLogo] = useState(false)
  const [listLogoShop, setListLogoShop] = useState([])
  const [isIntegratedTelegram, setIsIntegratedTelegram] = useState(false)
  const [isLoadingIntegrateTelegram, setIsLoadingIntegrateTelegram] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isOpenModalTelegram, setIsOpenModalTelegram] = useState(false)
  const [isSubmittingTelegram, setIsSubmittingTelegram] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const selectOptions = [
    {
      label: 'Time Zone',
      ref: timeZoneRef,
      defaultValue: 8,
      options: [{ value: 8, label: 'ASIA /SINGAPORE' }],
      isTimeZone: true,
    },
    {
      label: 'Currency',
      ref: currencyRef,
      defaultValue: 'SGD',
      options: [{ value: 'SGD', label: 'SGD' }],
      isCurrency: true,
    },
  ]

  const toggleModalAddLink = () => {
    setIsOpenModalAddLink(!isOpenModalAddLink)
  }

  const toggleModalAddLogo = () => {
    setIsOpenModalAddLogo(!isOpenModalAddLogo)
  }

  const removeExternalLink = (index) => {
    const newList = [...listLinkExternal]

    newList.splice(index, 1)

    setListLinkExternal(newList)

    dispatch(selectShopAndSelectShopDetail({ ...selectedShop, externalLink: newList }))
    dispatch(updateShopInMyShops({ ...selectedShop, externalLink: newList }))
  }

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const toggleModalTelegram = () => setIsOpenModalTelegram(!isOpenModalTelegram)

  const onSubmit = async (data) => {
    const list = listLogoShop
    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element.file) {
        const { msgResp } = await uploadApi.uploadPhoto([element.file])
        element.url = msgResp.url
      }
    }

    try {
      const postData = {
        ...data,
        logo: list.map((item) => item.url),
        timeZone: Number(timeZoneRef.current.value),
        currency: currencyRef.current.value,
        externalLink: listLinkExternal,
        shopSlug: removeDuplicateHyphens(removeAccents(data.shopName)),
        shopLink: domainGenerator() + removeDuplicateHyphens(removeAccents(data.shopName)),
      }
      if (!postData.shopLink.length && !postData.shopSlug.length) {
        throw new Error('Shop link is required')
      } else {
        await virtualShopApi.updateShop(postData, selectedShop.id)

        dispatch(
          selectShopAndSelectShopDetail({
            ...selectedShop,
            ...postData,
          })
        )
        dispatch(updateShopName(selectedShop.id, postData.shopName))
        // Update local storage with new shopSlug
        dispatch(updateShopInMyShops({ ...selectedShop, ...postData }))
        // Ensure the state is updated before proceeding
        await new Promise((resolve) => setTimeout(resolve, 0))

        const updatedShopLinks = userInfo.shopLinks.map((link) =>
          link.includes(selectedShop.shopSlug) ? postData.shopLink : link
        )

        setUserInfo({
          ...userInfo,
          shopLinks: updatedShopLinks,
        })

        addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
        history.replace(`/admin-console/${postData.shopSlug}/setting/general`)
      }
    } catch (e) {
      addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleIntegrateTelegram = async () => {
    try {
      setIsLoadingIntegrateTelegram(true)
      window.open(`${telegramLinkGenerator()}`, '_blank')

      setIsIntegratedTelegram(true)
      setIsLoadingIntegrateTelegram(false)
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleUninstallTelegram = async () => {
    CustomSwalFire.delete({
      title: 'Are you sure?',
      text: 'You want to uninstall telegram',
      icon: 'warning',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      onConfirm: async () => {
        try {
          setIsSubmittingTelegram(true)
          const postData = {
            ...selectedShop,
            telegramChatId: null,
          }

          await virtualShopApi.updateShop(postData, selectedShop.id)

          setIsIntegratedTelegram(false)
          dispatch(selectShopAndSelectShopDetail({ ...selectedShop, telegramChatId: null }))
          dispatch(updateShopInMyShops({ ...selectedShop, telegramChatId: null }))
          addToast('Uninstall success', { appearance: 'success', autoDismiss: true })
        } catch (error) {
          addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
        } finally {
          setIsSubmittingTelegram(false)
        }
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  const renderContact = () => (
    <ContactInputs
      register={register}
      errors={errors}
      createShopData={selectedShop}
      filterFields={['shopAddress']}
    />
  )

  useEffect(() => {
    if (selectedShop?.shopSlug) {
      setValue('shopName', selectedShop?.shopName)
      setValue('shopLink', selectedShop?.shopSlug)
      setValue('headline', selectedShop?.headline)
      setValue('shopAddress', selectedShop?.shopAddress)
      setValue('linkInstagram', selectedShop?.linkInstagram)
      setValue('linkFacebook', selectedShop?.linkFacebook)
      setValue('whatsApp', selectedShop?.whatsApp)
      setValue('timeZone', selectedShop?.timeZone)
      setValue('currency', selectedShop?.currency)
      setValue('telegramChatId', selectedShop?.telegramChatId)

      setListLinkExternal(selectedShop?.externalLink || [])
      setListLogoShop(selectedShop?.logo?.map((item) => ({ url: item, file: '' })))

      if (selectedShop?.telegramChatId) {
        setIsIntegratedTelegram(true)
      } else {
        setIsIntegratedTelegram(false)
      }
    }
  }, [selectedShop?.shopSlug])

  return (
    <div className='general bg-white pt-3 px-3 pb-5'>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>
        <div className='col-md-5 col-12'>
          <div>
            <ShopName
              error={errors?.shopName?.message}
              register={register}
              value={selectedShop?.shopName}
            />
            <div className='mb-3'>
              <InputField
                label='Address'
                type='text'
                id='shopAddress'
                defaultValue={selectedShop?.shopAddress}
                register={register('shopAddress')}
                error={errors?.shopAddress?.message}
                isRequired
              />
            </div>
            <div className='mb-3'>
              <InputField
                label='Headline'
                id='headline'
                defaultValue={selectedShop?.headline}
                placeholder='Enter headline'
                as='textarea'
                register={register('headline')}
                type='text'
                error={errors?.headline?.message}
              />
            </div>
            {selectOptions.map((option, index) => (
              <div className='mb-3' key={index}>
                <label htmlFor={option.label} className='form-label' style={{ fontWeight: 500 }}>
                  {option.label}
                </label>
                <CustomSelectBox
                  options={option.options}
                  value={option.defaultValue}
                  isDisabled={true}
                  isTimeZone={option.isTimeZone}
                  isCurrency={option.isCurrency}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='col-md-2 d-none d-md-block'></div>
        <div className='col-md-5 col-12'>
          <label
            htmlFor='contact'
            className='ps-0 w-100'
            style={{ fontWeight: 500, color: 'black' }}
          >
            Contact
          </label>
          {renderContact()}

          {/* integrate app with platforms */}
          <div className='mb-3 row d-none'>
            <div className='col-12 mb-2'>
              <span>Social Media</span>
            </div>
            <div className='col-12'>
              {isIntegratedTelegram ? (
                <div className='row align-items-center position-relative'>
                  {!selectedShop?.telegramChatId ? (
                    <div className='col-auto me-2'>
                      <>
                        <AiOutlineQuestionCircle
                          size={30}
                          className='cursor-pointer'
                          id='telegram-tooltip'
                          onClick={toggleModalTelegram}
                          color='#A6DFDE'
                        />
                        <Tooltip
                          isOpen={tooltipOpen}
                          target='telegram-tooltip'
                          toggle={toggleTooltip}
                        >
                          Click me!
                        </Tooltip>
                      </>
                    </div>
                  ) : null}
                  <div className='col-10'>
                    {!selectedShop?.telegramChatId ? (
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter ChatID telegram'
                        {...register('telegramChatId')}
                      />
                    ) : (
                      <div className='d-flex align-items-start'>
                        <div className='border p-2 d-flex rounded gap-2 align-items-center'>
                          <span className='w-100 fw-bold f-14'>
                            <img src={TelegramSVG} alt='telegram' width={24} className='me-1' />
                            Chat ID: {selectedShop?.telegramChatId}
                          </span>
                          <button
                            type='button'
                            className='btn btn-telegram rounded shadow-none uninstall text-secondary p-0 ms-auto'
                            onClick={handleUninstallTelegram}
                            disabled={isSubmittingTelegram}
                          >
                            <IoTrashOutline size={15} />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <button
                  type='button'
                  className='btn btn-telegram shadow-none d-flex align-items-center justify-content-center'
                  style={{ width: 100 }}
                  onClick={handleIntegrateTelegram}
                  disabled={isLoadingIntegrateTelegram}
                >
                  <img src={TelegramSVG} alt='telegram' width={24} className='me-2' />
                  {isLoadingIntegrateTelegram ? (
                    <div className='btn-telegram loading'></div>
                  ) : (
                    <span>Install</span>
                  )}
                </button>
              )}
            </div>
          </div>

          <div className='banner-section'>
            <div className='banner-label'>Banner</div>
            <div className='banner-action' onClick={toggleModalAddLogo}>
              {listLogoShop?.length > 0 ? (
                <div className='d-flex align-items-center gap-2'>
                  <MdPhotoLibrary size={20} />
                  <span>Change Banner</span>
                </div>
              ) : (
                <div className='d-flex align-items-center gap-2'>
                  <MdAddPhotoAlternate size={20} />
                  <span>Add Banner</span>
                </div>
              )}
            </div>
          </div>

          {listLogoShop?.length > 0 && (
            <div className='image-list'>
              {listLogoShop?.map((logo, index) => (
                <div className='image-item' key={index}>
                  <img src={logo.url} className='rounded shadow-sm zoom-image object-fit-cover' />
                </div>
              ))}
            </div>
          )}

          {/* External Links Section */}
          <div className='external-links-section'>
            <div className='section-header'>
              <span className='section-title'>External Links</span>
              <span className='add-link-btn' onClick={toggleModalAddLink}>
                {listLinkExternal?.length > 0 ? (
                  <>
                    <IoIosAdd size={22} />
                    Add Another Link
                  </>
                ) : (
                  <>
                    <IoIosAdd size={22} />
                    Add External Link
                  </>
                )}
              </span>
            </div>

            {listLinkExternal?.length > 0 && (
              <div className='links-container'>
                {listLinkExternal?.map((item, i) => (
                  <div key={i} className='link-item'>
                    <div className='link-content'>
                      <span className='link-name'>{item.name}:</span>
                      <a
                        href={item.value}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='link-url'
                        title={item.value} // Add tooltip for full URL on hover
                      >
                        {item.value}
                      </a>
                    </div>
                    <button
                      className='remove-btn'
                      onClick={() => removeExternalLink(i)}
                      title='Remove link' // Add tooltip
                    >
                      <img src={cancelImageIcon} alt='Remove' />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className='w-100'></div>
        <div className='col-12 mt-4'>
          <div className='d-flex align-items-center justify-content-center gap-3'>
            <CustomButton text='Cancel' color='slate' type='reset' icon={<GrPowerReset />} />

            <CustomButton
              text={isSubmitting ? 'Saving...' : 'Save'}
              type='submit'
              color='aqua'
              variant='contained'
              isDisabled={isSubmitting}
              className='ms-3'
              icon={<IoSaveOutline />}
            />
          </div>
        </div>
      </form>
      <ExternalLinkModal
        modal={isOpenModalAddLink}
        toggle={toggleModalAddLink}
        value={listLinkExternal}
        setValue={setListLinkExternal}
      />

      <LogoModal
        modal={isOpenModalAddLogo}
        toggle={toggleModalAddLogo}
        listImage={listLogoShop}
        setListImage={setListLogoShop}
      />

      <ModalTelegram
        modal={isOpenModalTelegram}
        toggle={toggleModalTelegram}
        className='modal-telegram custom-scrollbar'
      />
    </div>
  )
}

export default General
