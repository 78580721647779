import CustomButton from '_components/CustomButton'
import CustomSelectBox from '_components/CustomSelectBox'
import InputField from '_components/InputField'
import { DEFAULT_DELIVERY_OPTION, LIST_TIME } from '_utils/constant'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { IoSave } from 'react-icons/io5'
import { MdOutlineAttachMoney } from 'react-icons/md'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import DatePicker from '../components/DatePicker'

const DeliveryOptionsModal = (props) => {
  const {
    modal,
    handleOnClickOk,
    deliveryZones,
    deliveryTime,
    onChangeZoneDelivery = () => {},
    onChangeTimeDelivery,
    handleOnRemoveDeliveryZone = () => {},
    handleAddMoreDeliveryZone = () => {},
    deliveryHourStart,
    onChangeDeliveryHourStart,
    deliveryHourEnd,
    onChangeDeliveryHourEnd,
    toggle,
    selectedGroup = '',
    setSelectedGroup,
    optionGroup,
    isSubmitting = false,
    validationError,
  } = props

  const getDeliveryTime = (time) => {
    // 4: "00PM"
    if (typeof time === 'string' && time.length <= 4) {
      return JSON.stringify({ value: time, label: time })
    }

    return JSON.stringify(time)
  }

  useEffect(() => {
    if (deliveryZones.length === 0) {
      handleAddMoreDeliveryZone(
        JSON.parse(JSON.stringify([DEFAULT_DELIVERY_OPTION, DEFAULT_DELIVERY_OPTION]))
      )
    }
  }, [deliveryZones])

  return (
    <Modal isOpen={modal} toggle={toggle} centered className='modal-schedule-delivery'>
      <ModalHeader toggle={toggle} className='title'>
        Schedule Delivery
      </ModalHeader>
      <ModalBody>
        <div className='container p-0 '>
          {selectedGroup ? (
            <div className='input-delivery-group mb-2'>
              <CustomSelectBox
                options={optionGroup?.map((group) => ({ value: group.gid, label: group.name }))}
                value={selectedGroup}
                onChange={setSelectedGroup}
              />
            </div>
          ) : null}

          <div className='custom-select'>
            <DatePicker
              onChange={onChangeTimeDelivery}
              value={deliveryTime ?? moment().toDate()}
              minDate={moment().toDate()}
            />
          </div>

          <div className='group-hour-delivery m-0'>
            <div className='input-delivery-hour d-flex flex-column'>
              <div className='text-between'>Between</div>
              <CustomSelectBox
                className='mb-0'
                options={LIST_TIME.map((item) => ({ value: item.text, label: item.text }))}
                value={getDeliveryTime(deliveryHourStart)}
                onChange={(value) => onChangeDeliveryHourStart(value)}
                placeholder='Select start time'
                isTimer
              />
            </div>

            <div className='pb-4 pt-4 font-weight-bold icon-cross'>-</div>

            <div className='input-delivery-hour'>
              <div className='mb-0'>
                <div className='text-to'>To</div>
                <CustomSelectBox
                  className={classNames('mb-0', { 'input-invalid': validationError })}
                  options={LIST_TIME.map((item) => ({ value: item.text, label: item.text }))}
                  value={getDeliveryTime(deliveryHourEnd)}
                  onChange={(value) => onChangeDeliveryHourEnd(value)}
                  placeholder='Select end time'
                  isTimer
                  errors={validationError}
                />
              </div>
              {validationError && (
                <small
                  className='text-danger d-block'
                  style={{ fontSize: 10, transform: 'translateY(2px)' }}
                >
                  {validationError}
                </small>
              )}
            </div>
          </div>

          <div className={'sub-item-option align-items-center delivery-options'}>
            <p className='delivery-fee'>Delivery Fee</p>
            {deliveryZones.map((_, index) => (
              <div className='row align-items-start justify-content-between' key={index}>
                <div className='sub-item-info col-6 d-flex align-items-center'>
                  <span className='sub-txt'>{index + 1}. </span>
                  <InputField
                    className={'delivery-option w-100'}
                    placeholder={`Area ${index + 1}`}
                    value={deliveryZones[index].name}
                    onChange={(e) => {
                      onChangeZoneDelivery(index, 'name', e.target.value)
                    }}
                  />
                </div>
                <div className='sub-item-info col-4 d-flex align-items-center'>
                  <InputField
                    className={' delivery-option '}
                    type={'number'}
                    defaultValue={Number(deliveryZones[index].price)}
                    onChange={(e) => {
                      onChangeZoneDelivery(index, 'price', Number(e.target.value))
                    }}
                    icon={<MdOutlineAttachMoney size={16} />}
                  />
                </div>
                <div
                  className='sub-item-info col-auto'
                  onClick={() => handleOnRemoveDeliveryZone(index)}
                >
                  <CustomButton
                    variant='outlined'
                    color='slate'
                    className='border-0 mt-0 button-delete w-auto'
                    icon={<FaTrashAlt size={16} />}
                  />
                </div>
              </div>
            ))}
            <CustomButton
              onClick={handleAddMoreDeliveryZone}
              variant='text'
              text='Add more'
              color='aqua'
              className='mt-0 justify-content-start p-0 text-decoration-underline'
            />
          </div>
          <div className='row'>
            <div className='col-12 mt-3 px-0'>
              <CustomButton
                text={isSubmitting ? <Spinner color='light' size='sm' /> : 'OK'}
                className='mt-0'
                color='aqua'
                onClick={handleOnClickOk}
                variant='contained'
                icon={<IoSave />}
                disabled={!!validationError}
              />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeliveryOptionsModal

DeliveryOptionsModal.propTypes = {
  modal: PropTypes.bool,
  handleOnClickOk: PropTypes.func,
  handleRemoveRemember: PropTypes.func,
  deliveryZones: PropTypes.array,
  deliveryTime: PropTypes.object,
  onChangeZoneDelivery: PropTypes.func,
  onChangeDateDelivery: PropTypes.func,
  handleOnRemoveDeliveryZone: PropTypes.func,
  handleAddMoreDeliveryZone: PropTypes.func,
  hideDeliveryTime: PropTypes.bool,
  hideDeliveryZones: PropTypes.bool,
  validationError: PropTypes.string,
}
