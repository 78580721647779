/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import CustomSelectBox from '_components/CustomSelectBox'
import FulfillmentCalendar from '_components/FulfillmentCalendar'
import { CollapseContext } from '_contexts/CollapseMenuContext'
import React, { useContext } from 'react'
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs'

import { useWindowWidth } from '@react-hook/window-size'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import MenuItem from './MenuItem'
import './style.scss'

const RenderFulfillmentCalendar = React.memo(
  ({ isCollapse, typePage, isShowFulfillmentTag, toggleSidebar }) => {
    if (!isCollapse) {
      if (isShowFulfillmentTag) {
        return <FulfillmentCalendar typePage={typePage} toggleSidebar={toggleSidebar} />
      }
    }
    return null
  }
)

RenderFulfillmentCalendar.displayName = 'RenderFulfillmentCalendar'

const renderContent = ({
  isCollapse,
  myShops,
  selectedShop,
  listMenuItem,
  pathname,
  onChangeShop,
  toggleSidebar,
  typePage,
  isShowFulfillmentTag,
}) => (
  <>
    {myShops.length > 0 && (
      <div
        className={`px-2 wrapper-select-shop ${isCollapse ? 'd-none' : ''}`}
        style={{ marginTop: 13 }}
      >
        <CustomSelectBox
          options={myShops.map((shop) => ({
            value: shop.id,
            label: shop.shopName,
          }))}
          value={selectedShop}
          onChange={onChangeShop}
          disabled={pathname.includes('update-confirm')}
          isShopSelect
        />
      </div>
    )}
    <ul className={`${!isCollapse ? 'px-2' : 'ps-4'} list-menu pt-2 mb-0`}>
      {(listMenuItem || []).length
        ? (listMenuItem || []).map((item, index) => (
            <MenuItem key={index} item={item} index={index} toggleSidebar={toggleSidebar} />
          ))
        : null}
    </ul>
    <div className='w-100 d-flex'>
      <div className='prev-style-menu'>
        <span className='text-uppercase'>
          {pathname.includes('order-records') ? 'Order Dates' : 'Fulfillment'}
        </span>
      </div>
      <div className='calendar w-100'>
        <RenderFulfillmentCalendar
          isCollapse={isCollapse}
          typePage={typePage}
          isShowFulfillmentTag={isShowFulfillmentTag}
          toggleSidebar={toggleSidebar}
        />
      </div>
    </div>
  </>
)

const Sidebar = React.memo(
  ({ toggleSidebar, listMenuItem, onChangeShop, typePage, isShowFulfillmentTag }) => {
    const width = useWindowWidth()
    const isMobile = width <= 991
    const { pathname } = useLocation()

    const { isCollapse, toggleCollapse } = useContext(CollapseContext)

    const { selectedShop, myShops } = useSelector((state) => state.shop)

    return (
      <div className='pe-0'>
        <div className='d-flex justify-content-between' style={{ marginTop: !isMobile ? 35 : 0 }}>
          <div className='d-flex flex-column w-100'>
            {renderContent({
              isCollapse,
              myShops,
              selectedShop,
              listMenuItem,
              toggleSidebar,
              pathname,
              onChangeShop,
              typePage,
              isShowFulfillmentTag,
            })}
          </div>
          <div className='style-menu' onClick={toggleCollapse}>
            <div className='group'>
              <span className='text-uppercase'>menu</span>
              <span className='d-inline-block'>
                {isCollapse ? <BsFillCaretRightFill /> : <BsFillCaretLeftFill />}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

Sidebar.displayName = 'Sidebar'

export default Sidebar
