import virtualShopApi from '_api/virtualShop'
import CustomButton from '_components/CustomButton'
import InputField from '_components/InputField'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import React, { useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { IoSaveOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Tooltip } from 'reactstrap'
import { updateShopInMyShops } from '../../../redux/modules/shop'
import './style.scss'

function Payment() {
  const dispatch = useDispatch()
  const { selectedShop: shop } = useSelector((state) => state.shop)
  const { addToast } = useToasts()
  const [transactionFees, setTransactionFees] = useState(0)
  const [transactionFeesStatus, setTransactionFeesStatus] = useState(0)
  const [cashOnDeliveryPickup, setCashOnDeliveryPickUp] = useState(0)
  const [updatingTransactionFees, setUpdatingTransactionFees] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState({
    transactionFees: false,
    cod: false,
    paylah: false,
  })

  const toggleTooltip = (name) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  useEffect(() => {
    if (shop?.cashOnDeliveryPickup) {
      setCashOnDeliveryPickUp(shop?.cashOnDeliveryPickup)
    }

    if (shop?.transactionFees) {
      setTransactionFees(shop?.transactionFees)
    }

    if (shop?.transactionFeesStatus) {
      setTransactionFeesStatus(shop?.transactionFeesStatus)
    }
  }, [shop?.shopSlug])

  const handleAddTransactionFees = async (isCheck) => {
    try {
      setUpdatingTransactionFees(true)
      const data = {
        transactionFeesStatus: isCheck,
        transactionFees: parseFloat(transactionFees),
      }

      if (isNaN(data.transactionFees)) {
        return
      }

      await virtualShopApi.updateShopTransactionFees({ shopId: shop.id, data })
      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          transactionFees: parseFloat(transactionFees),
          transactionFeesStatus: isCheck,
        })
      )
      dispatch(updateShopInMyShops({ ...shop, transactionFees: parseFloat(transactionFees) }))
      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast(`${e.msgResp || e.message}`, { appearance: 'error', autoDismiss: true })
    } finally {
      setUpdatingTransactionFees(false)
    }
  }

  const handleUpdateCashOnDeliveryPickUp = async (isCheck) => {
    if (isCheck && !shop?.fulfillmentStatus) {
      return addToast('Please enable fulfillment first', { appearance: 'error', autoDismiss: true })
    }

    setCashOnDeliveryPickUp(isCheck)

    try {
      const data = {
        cashOnDeliveryPickup: isCheck,
      }

      await virtualShopApi.updateShopCashOnDeliveryPickup({ shopId: shop.id, data })
      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          cashOnDeliveryPickup: isCheck,
        })
      )
      dispatch(updateShopInMyShops({ ...shop, cashOnDeliveryPickup: isCheck }))
      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleOnCheck = (e, filedName = '') => {
    const isCheck = e.target.checked ? 1 : 0
    switch (filedName) {
      case 'transactionFees':
        if (isCheck) {
          setTransactionFeesStatus(isCheck)
        } else {
          setTransactionFeesStatus(isCheck)
          handleAddTransactionFees(isCheck)
        }
        break
      case 'cashOnDeliveryPickup':
        handleUpdateCashOnDeliveryPickUp(isCheck)
        break
      default:
        break
    }
  }

  return (
    <div className='payment bg-white position-relative'>
      <div className='payment__section row mb-3 align-items-center'>
        <div className='payment__section-content col-12 col-md-10'>
          <p className='payment__title text-black fw-bold mb-0'>Add transaction fees</p>
          <div className='d-flex align-items-center'>
            <p className='payment__description mb-0'>
              Customers will be charged the transaction fees
            </p>
          </div>
        </div>
        <div className='payment__section-input col-12 col-md-2'>
          <InputField
            type={'checkbox'}
            isSwitch
            checked={!!transactionFeesStatus}
            onChange={(e) => handleOnCheck(e, 'transactionFees')}
          />
        </div>
        <div className='w-100'></div>
        {transactionFeesStatus ? (
          <div className='payment__transaction-fees col-12 col-md-6 mt-1'>
            <div className='position-relative'>
              <CurrencyFormat
                value={transactionFees || 0}
                displayType={'input'}
                placeholder='Enter transaction fee'
                thousandSeparator={true}
                prefix={'$'}
                className='form-control custom-input'
                onValueChange={(values) => {
                  const { value } = values
                  setTransactionFees(value)
                }}
              />
              <CustomButton
                text={updatingTransactionFees ? 'Saving...' : 'Save'}
                color='aqua'
                onClick={() => handleAddTransactionFees(transactionFeesStatus)}
                isDisabled={updatingTransactionFees}
                className='mt-0 w-auto save-button'
                variant='contained'
                icon={<IoSaveOutline />}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='payment__section row mb-3 align-items-center'>
        <div className='payment__section-content col-12 col-md-10'>
          <p className='payment__title text-black fw-bold mb-0'>Cash on Delivery & Pickup</p>
          <div className='d-flex align-items-center'>
            <p className='payment__description mb-0'>
              Allow customer to pay upon Delivery & Pickup
            </p>
            <AiOutlineInfoCircle className='ms-2 info-icon' id='codTooltip' />
            <Tooltip
              placement='right'
              isOpen={tooltipOpen.cod}
              target='codTooltip'
              toggle={() => toggleTooltip('cod')}
              className='custom-tooltip'
            >
              Need has delivery zone when COD/COP is enabled
            </Tooltip>
          </div>
        </div>
        <div className='payment__section-input col-12 col-md-2'>
          <InputField
            type={'checkbox'}
            isSwitch
            checked={!!cashOnDeliveryPickup}
            onChange={(e) => handleOnCheck(e, 'cashOnDeliveryPickup')}
          />
        </div>
      </div>
      <div className='payment__section row mb-3 align-items-center'>
        <div className='payment__section-content col-12 col-md-10'>
          <p className='payment__title text-black fw-bold mb-0'>PayLah! / PayNow</p>
          <div className='d-flex align-items-center'>
            <p className='payment__description mb-0'>Allow customer to pay via PayLah/PayNow</p>
          </div>
        </div>
        <div className='payment__section-input col-12 col-md-2'>
          <InputField type={'checkbox'} isSwitch checked={false} disabled />
        </div>
      </div>
    </div>
  )
}

export default Payment
