import virtualShopApi from '_api/virtualShop'
import CustomButton from '_components/CustomButton'
import CustomSwalFire from '_components/CustomSwalFire'
import InputField from '_components/InputField'
import ModalDeliveryZones from '_components/ModalDeliveryZones'
import {
  addDelivery,
  addPickup,
  getListDelivery,
  getListPickup,
  toggleFulfillmentActive,
} from '_redux/modules/fulfillment'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { PACKAGE_BASIC, SUBSCRIPTION_STATUS_UNPAID } from '_utils/constant'
import { idGenerator } from '_utils/functions/generator'
import { setDataShopDetail } from '_utils/localData'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { GoPlus } from 'react-icons/go'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import DeliveryOption from './components/Delivery/DeliveryOption'
import PickUpOption from './components/Pickup/PickUpOption'

import { updateShopInMyShops } from '../../../redux/modules/shop'
import './style.scss'

function Fulfillment() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { listDelivery, listPickUp } = useSelector((state) => state.fulfillment)
  const { selectedShop: shop } = useSelector((state) => state.shop)
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [listDeliveryOption, setListDeliveryOption] = useState([])
  const [listPickUpOption, setListPickUpOption] = useState([])
  const [fulfillmentStatus, setFulfillmentStatus] = useState(false)
  const [isAddZones, setIsAddZones] = useState(false)

  const { listInvoiceSubscription } = useSelector((state) => state.subscription)

  useEffect(() => {
    if (!isEmpty(shop)) {
      setDataShopDetail(shop)
    }
  }, [shop?.shopSlug])

  useEffect(() => {
    if (shop?.fulfillmentStatus) {
      setFulfillmentStatus(shop?.fulfillmentStatus)
    } else {
      setFulfillmentStatus(false)
    }
  }, [shop?.fulfillmentStatus])

  const shouldDisplayEvents = () => {
    if ((listInvoiceSubscription || []).length === 1) {
      return listInvoiceSubscription[0].subscriptionPackageType !== PACKAGE_BASIC
    }
    if ((listInvoiceSubscription || []).length > 1) {
      return listInvoiceSubscription.some(
        (subscription) =>
          subscription.subscriptionPackageType !== PACKAGE_BASIC &&
          subscription.status !== SUBSCRIPTION_STATUS_UNPAID
      )
    }
    return false
  }

  const toggleAddZones = () => {
    setIsAddZones(!isAddZones)
  }

  const handleAddDeliveryOption = () => {
    dispatch(
      addDelivery({
        id: `FAKE_ID${idGenerator()}`,
        optionName: '',
        fee: '',
        instructions: '',
      })
    )
  }

  const handleAddPickUpOption = () => {
    dispatch(
      addPickup({ id: `FAKE_ID${idGenerator()}`, optionName: '', instructions: '', fee: '' })
    )
  }

  useEffect(() => {
    let isMounted = true

    if (shop?.id) {
      const fetchData = async () => {
        setLoading(true)
        await Promise.all([dispatch(getListDelivery(shop?.id)), dispatch(getListPickup(shop?.id))])
        if (isMounted) {
          setLoading(false)
        }
      }

      fetchData()
    }

    return () => {
      isMounted = false
    }
  }, [pathname, shop?.id])

  useEffect(() => {
    if (listDelivery?.length === 0) {
      dispatch(
        addDelivery({
          id: `FAKE_ID${idGenerator()}`,
          optionName: '',
          fee: '',
          instructions: '',
        })
      )
      setListDeliveryOption([
        {
          id: `FAKE_ID${idGenerator()}`,
          optionName: '',
          fee: '',
          instructions: '',
        },
      ])
    } else {
      // sort createAt asc
      setListDeliveryOption(listDelivery)
    }
  }, [listDelivery])

  useEffect(() => {
    if (listPickUp?.length === 0) {
      dispatch(
        addPickup({
          id: `FAKE_ID${idGenerator()}`,
          optionName: '',
          instructions: '',
          fee: '',
        })
      )
      setListPickUpOption([
        { id: `FAKE_ID${idGenerator()}`, optionName: '', instructions: '', fee: '' },
      ])
    } else {
      setListPickUpOption(listPickUp)
    }
  }, [listPickUp])

  const onUpdateIsTurnOnFulfillment = (e) => {
    if (e.target.checked === false) {
      CustomSwalFire.delete({
        title: 'Are you sure turn off?',
        text: '',
        icon: 'warning',
        confirmButtonText: 'Yes, turn off it!',
        onConfirm: async () => {
          const data = {
            cashOnDeliveryPickup: Number(false),
          }
          await virtualShopApi.updateShopCashOnDeliveryPickup({ shopId: shop.id, data })
          dispatch(toggleFulfillmentActive(shop?.id, false))
          setFulfillmentStatus(false)
          dispatch(
            selectShopAndSelectShopDetail({
              ...shop,
              fulfillmentStatus: false,
              cashOnDeliveryPickup: false,
            })
          )
          dispatch(
            updateShopInMyShops({ ...shop, fulfillmentStatus: false, cashOnDeliveryPickup: false })
          )
          addToast('Turn off fulfillment successfully!', {
            appearance: 'success',
            autoDismiss: true,
          })
        },
        onError: (error) => {
          CustomSwalFire.error({
            title: 'Error!',
            text: error.message,
          })
        },
      })
    } else {
      const hasOptionNameInDelivery = listDeliveryOption.some(
        (item) => item.optionName.trim() !== ''
      )
      const hasOptionNameInPickup = listPickUpOption.some((item) => item.optionName.trim() !== '')

      setFulfillmentStatus(true)
      dispatch(
        selectShopAndSelectShopDetail({
          ...shop,
          fulfillmentStatus: true,
        })
      )
      dispatch(updateShopInMyShops({ ...shop, fulfillmentStatus: true }))
      if (hasOptionNameInDelivery || hasOptionNameInPickup) {
        dispatch(toggleFulfillmentActive(shop?.id, true))
      }
      addToast('Turn on fulfillment successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
    }
  }

  return (
    <div className='fulfillment py-3 px-5 bg-white position-relative'>
      <div className='row mb-2'>
        <div className='col-12 col-md-6'>
          <p className='text-black mb-0 fw-bold'>Delivery & Pickup</p>
          <p className='mb-2'>
            Create delivery & Pick-up options and set how much you charge for them.
          </p>
        </div>

        <div className='col-12 col-md-6'>
          <div className='d-flex align-items-center w-100 h-100 justify-content-start justify-content-lg-end'>
            <span>Turn on/off fulfillment:</span>
            <InputField
              isSwitch
              type='checkbox'
              className='ms-3'
              checked={fulfillmentStatus}
              onChange={(e) => onUpdateIsTurnOnFulfillment(e)}
              offset={{ top: 6 }}
            />
          </div>
        </div>
      </div>

      {shop?.fulfillmentStatus ? (
        <div
          className={`row fulfillment-options ${loading ? 'opacity-50 pointer-events-none' : ''}`}
        >
          <div className='col-12 col-lg-5 mb-3 mb-lg-0'>
            <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-3'>
              <span className='text-black mb-2 mb-md-0'>Delivery Options</span>
              {shouldDisplayEvents() && (
                <CustomButton
                  onClick={toggleAddZones}
                  text='Add zones to Co-Bee'
                  icon={<MdFormatListBulletedAdd />}
                  className='mt-0 btn-add-zones'
                />
              )}
            </div>
            {listDeliveryOption?.map((item, i) => (
              <DeliveryOption key={item.id} delivery={item} index={i} shopId={shop?.id} />
            ))}

            <CustomButton
              icon={<GoPlus />}
              onClick={handleAddDeliveryOption}
              text='ADD'
              className='border-dash w-100'
            />
          </div>

          <div className='col-12 col-lg-5 mb-3 mb-lg-0'>
            <div
              className={classNames('d-flex mb-0', {
                'mb-lg-1': !shouldDisplayEvents(),
                'mb-lg-3': shouldDisplayEvents(),
              })}
              style={{ height: 37 }}
            >
              <span className='text-black'>Pick-up Options</span>
            </div>
            {listPickUpOption?.map((item, i) => (
              <PickUpOption key={item.id} index={i} pickup={item} shopId={shop?.id} />
            ))}
            <CustomButton
              icon={<GoPlus />}
              onClick={handleAddPickUpOption}
              text='ADD'
              className='border-dash w-100'
            />
          </div>
        </div>
      ) : (
        <p className='badge bg-info'>Currently shop does not offer delivery/pickup services.</p>
      )}

      <ModalDeliveryZones isOpen={isAddZones} toggle={toggleAddZones} listDelivery={listDelivery} />
    </div>
  )
}

export default Fulfillment
