import CustomButton from '_components/CustomButton'
import React from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export default function ModalOrderComment({
  openOrderComment,
  toggleOpenModalComment,
  selectedOrder,
  ...args
}) {
  const modalTitleStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  return (
    <Modal
      isOpen={!!openOrderComment}
      toggle={toggleOpenModalComment}
      className='modal-order-comment'
      centered
      {...args}
    >
      <ModalHeader className='border-0' style={modalTitleStyle} toggle={toggleOpenModalComment}>
        Comment by {selectedOrder?.uName}
      </ModalHeader>
      <ModalBody className='d-flex flex-column align-items-center pt-0'>
        <p>{selectedOrder?.comment}</p>
        <CustomButton
          text='OK'
          variant='contained'
          color='aqua'
          icon={<IoMdCheckmark />}
          onClick={toggleOpenModalComment}
        />
      </ModalBody>
    </Modal>
  )
}
