/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import InputField from '_components/InputField'
import { DEFAULT_SUB_ITEM } from '_utils/constant'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { IoIosAdd } from 'react-icons/io'
import { MdOutlineAttachMoney } from 'react-icons/md'
import CustomButton from '../../../components/CustomButton'
import { DEFAULT_OPTION } from '../../../utils/constant'
import InputProduct from './InputProduct'

const SubItem = (props) => {
  const {
    control,
    register,
    nestIndex,
    productIndex,
    removeSubItem,
    insertSubItem,
    subItem,
    appendSubItem,
    watch,
    errors,
  } = props
  const {
    fields,
    remove: removeOption,
    append: appendOption,
  } = useFieldArray({
    control,
    name: `products.${productIndex}.subItems.${nestIndex}.list`,
  })
  const isError = !!errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list
  const watchFieldArray = watch(`products.${productIndex}.subItems.${nestIndex}.list`)
  const controlledFields =
    watchFieldArray?.length > 0
      ? fields.map((field, _index) => ({
          ...field,
          ...watchFieldArray[_index],
        }))
      : []

  return (
    <div className='sub-item c-form is-active'>
      <div className='sub-item-div'>
        <div className='row'>
          <div className='col-3 align-self-center col-index'>Sub item {nestIndex + 1}</div>
          <div className='col-9'>
            <InputField
              type='text'
              placeholder='Add Category'
              register={register(`products.${productIndex}.subItems.${nestIndex}.type`)}
              error={errors?.products?.[productIndex]?.subItems?.[nestIndex]?.type?.message}
              style={{ background: '#ecfbfb', border: 'none', borderRadius: 0 }}
            />
          </div>
        </div>
        {controlledFields?.map((_item, index) => (
          <div
            className={`row sub-item-option align-items-${isError ? 'start' : 'center'}`}
            key={index}
          >
            <div className='col-1'>
              <div className='d-block text-secondary'>{index + 1}.</div>
            </div>
            <div className='sub-item-info col-6 ps-4'>
              <InputProduct
                type='text'
                placeholder='Name...'
                className='border-top-0 border-start-0 border-end-0 rounded-0 w-100 border-1'
                register={register(
                  `products.${productIndex}.subItems.${nestIndex}.list.${index}.name`
                )}
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.name
                    ?.message
                }
                style={{ borderColor: '#ccc' }}
              />
            </div>
            <div className='col-1 text-center'>
              <span className='d-block text-secondary'>$</span>
            </div>
            <div className='sub-item-info col-3'>
              <InputProduct
                icon={<MdOutlineAttachMoney />}
                placeholder='Price'
                // as={CurrencyFormat}
                register={register(
                  `products.${productIndex}.subItems.${nestIndex}.list.${index}.price`
                )}
                value={controlledFields?.[index]?.price}
                className='border-top-0 border-start-0 border-end-0 rounded-0 w-100 border-1'
                // thousandSeparator
                // decimalScale={2}
                // onValueChange={({ value }) =>
                //   setValue(
                //     `products.${productIndex}.subItems.${nestIndex}.list.${index}.price`,
                //     value
                //   )
                // }
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.price
                    ?.message
                }
                style={{ borderColor: '#ccc' }}
              />
            </div>
            <div className='col-1'>
              <div
                className='btn-remove cursor-pointer'
                style={{ filter: 'grayscale(1)' }}
                onClick={() => removeOption(index)}
              ></div>
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='sub-item-info col-12 ps-0'>
            {/* <a onClick={() => appendOption({ ...DEFAULT_OPTION })}>Add Option</a> */}
            <CustomButton
              type='button'
              variant='contained'
              color='aqua'
              text='Add Option'
              icon={<IoIosAdd />}
              onClick={() => appendOption({ ...DEFAULT_OPTION })}
              className='mt-0 w-auto'
            />
          </div>
        </div>
      </div>
      <div className='sub-item-div sub-item-div-last'>
        <div className='row'>
          <div className='col-6 ps-0'>
            <InputField
              label='Required'
              isSwitch
              register={register(`products.${productIndex}.subItems.${nestIndex}.required`)}
              labelStyle={{ marginBottom: 0, marginRight: '0.25rem' }}
              className='d-flex align-items-center'
            />
          </div>
          <div className='col-6'>
            <div
              className='d-flex justify-content-end btn-action-01'
              style={{ filter: 'grayscale(1)' }}
            >
              <div
                className='btn-add-01 cursor-pointer'
                style={{ marginRight: '0.75rem' }}
                onClick={() => appendSubItem(JSON.parse(JSON.stringify({ ...DEFAULT_SUB_ITEM })))}
              ></div>
              <div
                className='btn-copy me-0 cursor-pointer'
                onClick={() =>
                  insertSubItem(
                    parseInt(nestIndex + 1, 10),
                    JSON.parse(JSON.stringify({ ...subItem }))
                  )
                }
              ></div>
              <div
                className='btn-remove cursor-pointer'
                onClick={() => removeSubItem(nestIndex)}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubItem
