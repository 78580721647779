import virtualShopApi from '_api/virtualShop'
import CustomButton from '_components/CustomButton'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import React, { useEffect, useState } from 'react'
import { GoInbox } from 'react-icons/go'
import { IoClose, IoSave } from 'react-icons/io5'
import { TiCancel } from 'react-icons/ti'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { updateShopInMyShops } from '../../../../redux/modules/shop'

const privacyModeActive = 1

function ModalShowBlackList({ modal, toggle }) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { selectedShop } = useSelector((state) => state.shop)
  const [blackList, setBlackList] = useState()

  useEffect(() => {
    setBlackList(selectedShop?.allowedAccount)
  }, [modal])

  const handleOnClick = (data) => {
    const newBlackList = blackList.filter((item) => item !== data)
    setBlackList(newBlackList)
  }

  const handleWhenSaveBlackList = async () => {
    const countBlackList = selectedShop?.allowedAccount?.length
    if (blackList?.length !== countBlackList) {
      try {
        const data = {
          privacyMode: privacyModeActive,
          allowedAccount: blackList,
        }
        await virtualShopApi.updateShop(data, selectedShop?.id)
        dispatch(
          selectShopAndSelectShopDetail({
            ...selectedShop,
            allowedAccount: blackList,
          })
        )
        dispatch(updateShopInMyShops({ ...selectedShop, allowedAccount: blackList }))
        toggle()
        addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
      } catch (e) {
        addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
      }
    } else {
      toggle()
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered size='md'>
      <ModalHeader toggle={toggle}>White List Management</ModalHeader>
      <ModalBody>
        <div className='black__list'>
          <div className='black__list-box'>
            {blackList?.length > 0 ? (
              blackList.map((item, i) => (
                <div key={i} className='black__list-item'>
                  <span>{item}</span>
                  <IoClose className='remove-icon' onClick={() => handleOnClick(item)} />
                </div>
              ))
            ) : (
              <NotFoundDataComponent
                icon={<GoInbox />}
                subtitle='No accounts in white list'
                style={{ margin: '30px auto' }}
              />
            )}
          </div>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <CustomButton
            variant='outlined'
            onClick={handleWhenSaveBlackList}
            text='Cancel'
            icon={<TiCancel />}
            color='slate'
          />
          <CustomButton
            variant='contained'
            onClick={handleWhenSaveBlackList}
            text='Save Changes'
            icon={<IoSave />}
            color='aqua'
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalShowBlackList
