/* eslint-disable no-confusing-arrow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable function-paren-newline */
import lazadaApi from '_api/lazada'
import productApi from '_api/product'
import { TURN_ON } from '_utils/constant'
import { generateIdWithLength } from '_utils/functions/generator'
import { selectShopAndSelectShopDetail, updateShopInMyShops } from './shop'

import {
  ADD_PRODUCT_SUCCESS,
  CLEAR_LAZADA_CATEGORY_SUGGESTIONS,
  CLEAR_PRODUCT_FLASH,
  CLEAR_SELECT_PRODUCT_LAZADA,
  COPY_PRODUCT,
  DELETE_PRODUCT_LAZADA,
  DELETE_PRODUCT_SUCCESS,
  EXPORT_PRODUCT_TO_LAZADA,
  FETCH_LIST_PRODUCT,
  FETCH_LIST_PRODUCT_LAZADA,
  FETCH_LIST_VARIATION_PRODUCT,
  FETCH_LIST_VARIATION_PRODUCT_FAILED,
  GET_BRAND_BY_PAGES,
  GET_LAZADA_CATEGORY_SUGGESTIONS,
  HANDLE_ADD_VARIATION,
  HANDLE_UPDATE_VARIATION,
  PUSH_TO_LAZADA,
  PUSH_TO_LAZADA_FAILED,
  PUSH_TO_LAZADA_SUCCESS,
  SELECT_PRODUCT_LAZADA,
  SELECT_PRODUCT_TO_PUSH_LAZADA,
  SELECT_TAB,
  SWAP_DOWN_PRODUCT,
  SWAP_UP_PRODUCT,
  TOGGLE_ACTIVE_PRODUCT,
  TOGGLE_SUB_ITEM,
  TOGGLE_VARIATIONS,
  UPDATE_EFFECT,
  UPDATE_LIST_PRODUCT_LAZADA,
  UPDATE_NEW_LIST_PRODUCT,
  UPDATE_NEW_LIST_PRODUCT_LAZADA,
  UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_SINGLE_PRODUCT_LAZADA,
  UPLOAD_IMAGE_PRODUCT,
  UPLOAD_IMAGE_PRODUCT_LAZADA,
} from '../constants/productConstants'

export const COPY_PRODUCT_SUCCESS = 'COPY_PRODUCT_SUCCESS'
export const COPY_PRODUCT_FAILURE = 'COPY_PRODUCT_FAILURE'

const initialState = {
  listProduct: [],
  error: null,
  listProductLazada: [],
  tabSelected: null,
  productSelected: null,
  productLazadaSelected: null,
  listLazadaCategorySuggestions: [],
  productType: '',
  brandByPages: [],
  errorMessageCategory: '',
  loadingUploadImage: false,
}

// Thêm action creator mới
export const clearProductFlash = (productId) => ({
  type: CLEAR_PRODUCT_FLASH,
  payload: { productId },
})

export const updateVariationProduct = (data) => async (dispatch, getState) => {
  try {
    const { product } = getState()
    const { listProductLazada } = product
    const newListProductLazada = [...listProductLazada]

    const updatedList = newListProductLazada.map((item) => {
      if (item.id === data.productId) {
        return {
          ...item,
          listVariationProduct: data.listVariationProduct,
          variations: data.variations,
        }
      }

      return item
    })

    await lazadaApi.modifyLazadaVariationProduct(data)

    dispatch({
      type: HANDLE_UPDATE_VARIATION,
      payload: {
        listProductLazada: updatedList,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const clearProductLazadaSelected = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECT_PRODUCT_LAZADA,
    payload: { productLazadaSelected: null, productType: '' },
  })
}

export const selectAndToggleProductLazadaModal =
  (productLazada, productType) => (dispatch, getState) => {
    const { product } = getState()
    const { listProductLazada } = product

    const updatedList = listProductLazada.map((item) => {
      if (item.id === productLazada.id) {
        return {
          ...item,
          isOpenModalEdit: !item.isOpenModalEdit,
        }
      }
      return item
    })

    dispatch({
      type: SELECT_PRODUCT_LAZADA,
      payload: {
        productLazadaSelected: productLazada,
        productType,
        listProductLazada: updatedList,
      },
    })
  }

export const handleAddVariation = (postData, productSelected) => async (dispatch) => {
  try {
    const convertPostData = {
      ...postData,
      listVariationProduct: postData.listVariationProduct.map((item) => {
        const { createdDate, createdUserId, shopId, pid, codeProd, ...restItem } = item

        const matchVariant = productSelected.listVariationProduct?.find((mock) =>
          Object.entries(mock.saleProp).every(
            ([key, value]) => item.saleProp.hasOwnProperty(key) && item.saleProp[key] === value
          )
        )

        if (matchVariant) {
          restItem.id = matchVariant.id
          restItem.sku_id = matchVariant.sku_id
        }

        return restItem
      }),
    }

    const seenItems = []
    const uniqueArray = convertPostData.listVariationProduct.map((item) => {
      const newItem = { ...item }

      const itemIdSku = `${item.id}-${item.sku_id}`

      if (!seenItems.includes(itemIdSku)) {
        seenItems.push(itemIdSku)
      } else {
        delete newItem.id
        delete newItem.sku_id
      }

      return newItem
    })

    const newConvertData = {
      ...convertPostData,
      listVariationProduct: uniqueArray,
    }

    await lazadaApi.modifyLazadaVariationProduct(newConvertData)

    dispatch({
      type: HANDLE_ADD_VARIATION,
      payload: {},
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateSingleProductLazada = (data, shopId) => async (dispatch) => {
  try {
    const postData = {
      shopId,
      productId: data.id,
      name: data.name,
      description: data.description,
      quantity: data.quantity,
      price: data.price,
      photoUrl: data.photoUrl,
      height: Number(data.height) || data.height,
      length: Number(data.length) || data.length,
      width: Number(data.width) || data.width,
      weight: Number(data.weight) || data.weight,
      active: Number(data.active),
      categoryId: data.categoryId,
    }

    await lazadaApi.updateSingleProduct(postData)

    dispatch({
      type: UPDATE_SINGLE_PRODUCT_LAZADA,
      payload: {},
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const updateNewListProduct = (newList) => (dispatch) => {
  const sanitizedList = newList.map((product) => ({
    ...product,
    isOpenSubItems: Boolean(product.isOpenSubItems),
  }))

  dispatch({
    type: UPDATE_NEW_LIST_PRODUCT,
    payload: { listProduct: sanitizedList },
  })
}

export const updateNewListProductLazada = (newList) => (dispatch) => {
  dispatch({
    type: UPDATE_NEW_LIST_PRODUCT_LAZADA,
    payload: { listProductLazada: newList },
  })
}

export const selectTab = (tab) => (dispatch) => {
  dispatch({
    type: SELECT_TAB,
    payload: { tabSelected: tab },
  })
  localStorage.setItem('selectedTabProduct', tab)
}

// export const fetchListVariationProductLazada = () => async (dispatch, getState) => {
//   const { product } = getState()
//   const { listProductLazada } = product
//   const listProductId = listProductLazada.map((prod) => ({
//     id: prod.id,
//     isPendingToPush: prod.isPendingToPush,
//   }))

//   try {
//     const responses = await Promise.all(
//       listProductId.map(async (prod) => {
//         if (prod.isPendingToPush) return []
//         const res = await lazadaApi.fetchListVariationProduct(prod.id)
//         return res.msgResp
//       })
//     )

//     const responseMap = {}

//     responses.forEach((response, index) => {
//       responseMap[`res${index + 1}`] = response
//     })

//     const updatedListProduct = listProductLazada.map((prod) => {
//       const productId = prod.id

//       const allVariations = Object.values(responseMap).flatMap((variations) => variations)

//       const matchingVariations = allVariations.filter((variation) => variation.pid === productId)

//       if (matchingVariations.length) {
//         return {
//           ...prod,
//           listVariationProduct: matchingVariations,
//         }
//       }

//       return prod
//     })

//     dispatch({
//       type: FETCH_LIST_VARIATION_PRODUCT,
//       payload: {
//         listProductLazada: updatedListProduct,
//       },
//     })
//     localStorage.setItem('listProductLazada', JSON.stringify(updatedListProduct))
//   } catch (error) {
//     throw new Error(error?.msgResp || error.message)
//   }
// }
export const fetchListVariationProductLazada = () => async (dispatch, getState) => {
  const { listProductLazada } = getState().product

  try {
    const responses = await Promise.allSettled(
      listProductLazada.map(async (prod) => {
        if (prod.isPendingToPush) return { id: prod.id, variations: [] }
        try {
          const { msgResp } = await lazadaApi.fetchListVariationProduct(prod.id)
          return { id: prod.id, variations: msgResp }
        } catch (error) {
          return { id: prod.id, variations: [], error: error.message }
        }
      })
    )

    const updatedListProduct = listProductLazada.map((prod) => {
      const response = responses.find((r) => r.status === 'fulfilled' && r.value.id === prod.id)
      const variations = response?.value.variations || []
      const matchingVariations = variations.filter((v) => v.pid === prod.id)

      return matchingVariations.length
        ? { ...prod, listVariationProduct: matchingVariations }
        : prod
    })

    dispatch({
      type: FETCH_LIST_VARIATION_PRODUCT,
      payload: { listProductLazada: updatedListProduct },
    })

    localStorage.setItem('listProductLazada', JSON.stringify(updatedListProduct))

    const failedProducts = responses
      .filter((r) => r.status === 'rejected' || r.value.error)
      .map((r) => r.value?.id)
      .filter(Boolean)

    if (failedProducts.length) {
      dispatch({
        type: FETCH_LIST_VARIATION_PRODUCT_FAILED,
        payload: { failedProductsLazada: failedProducts },
      })
    }
  } catch (error) {
    console.error('Error in fetchListVariationProductLazada:', error)
    throw new Error(error?.msgResp || error.message)
  }
}

export const fetchListVariationByProductId = (prodId) => async (dispatch, getState) => {
  try {
    const { product } = getState()
    const { listProductLazada } = product

    const { msgResp } = await lazadaApi.fetchListVariationProduct(prodId)

    const productIndex = listProductLazada.findIndex((p) => p.id === prodId)

    if (productIndex !== -1) {
      const updatedList = [...listProductLazada]
      updatedList[productIndex] = {
        ...updatedList[productIndex],
        listVariationProduct: msgResp,
      }

      dispatch({
        type: FETCH_LIST_VARIATION_PRODUCT,
        payload: { listProductLazada: updatedList },
      })
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const fetchListProductLazada = (shopId) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProductLazada } = product
  const currentListProductLazada = [...listProductLazada]
    .filter((item) => !item.item_id)
    .map((item) => ({
      ...item,
      isOpenVariations: false,
    }))

  try {
    const { msgResp } = await lazadaApi.fetchListProduct(shopId)
    const mappedResponse = msgResp.map((item) => ({
      ...item,
      isOpenVariations: false,
      availableOfStock: item.quantity,
    }))

    if (currentListProductLazada.length) {
      dispatch({
        type: FETCH_LIST_PRODUCT_LAZADA,
        payload: {
          listProductLazada: [...currentListProductLazada, ...mappedResponse],
        },
      })
    } else {
      dispatch({
        type: FETCH_LIST_PRODUCT_LAZADA,
        payload: {
          listProductLazada: mappedResponse,
        },
      })
    }
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const fetchListProduct = () => (dispatch, getState) => {
  const { shop } = getState()
  const { selectedShop } = shop

  const sortedListProducts = selectedShop?.products.sort((a, b) => b.createdAt - a.createdAt)

  try {
    const listProductConverted = sortedListProducts?.map((item) => ({
      ...item,
      isCopied: false, // Set giá trị mặc định là false thay vì dùng Boolean()
      subItems: item?.subItems?.map((subItem) => ({
        ...subItem,
        isOpenSubItems: false,
        id: generateIdWithLength(20),
        list: subItem?.list?.map((list) => ({
          ...list,
          id: generateIdWithLength(20),
        })),
      })),
    }))

    if (listProductConverted?.length) {
      dispatch({
        type: FETCH_LIST_PRODUCT,
        payload: { listProduct: listProductConverted },
      })
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateProduct = (data, shopInfo) => async (dispatch, getState) => {
  try {
    const { product, shop } = getState()
    const { listProduct } = product
    const { shopDetail } = shop

    const convertDate = (date) => {
      if (date) {
        const format = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        const hours = `0${date.getHours()}`.slice(-2)
        const minutes = `0${date.getMinutes()}`.slice(-2)
        const newFormat = format.split('/')
        return `${newFormat[1]}-${newFormat[0]}-${newFormat[2]} ${hours}:${minutes}`
      }
    }

    const dataDateSelection = {
      date: convertDate(data?.dateSelection),
      maxQtyPerDay: Number(data?.maxOrderQtyByDate),
    }

    const convertDataForServer = {
      ...data,
      dateSelectInfo: shopInfo.dateSelection === TURN_ON ? dataDateSelection : null,
      subItems: data.subItems.map((subItem) => {
        const { isOpenSubItems, ...restSubItem } = subItem
        return restSubItem
      }),
    }

    const convertDataForState = {
      ...data,
      price: parseFloat(data?.price),
      inventory: Number(data?.inventory),
      dateSelectInfo: shopInfo.dateSelection === TURN_ON ? dataDateSelection : null,
    }

    const newListUpdate = [...listProduct]
    const findIndex = newListUpdate.findIndex((item) => item.id === data.id)
    newListUpdate[findIndex] = { ...convertDataForState }
    newListUpdate[findIndex].isCopied = false

    dispatch(selectShopAndSelectShopDetail({ ...shopDetail, products: newListUpdate }))
    dispatch(updateNewListProduct(newListUpdate))

    await productApi.updateShopProduct(data.id, shopDetail?.id, convertDataForServer)

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: { listProduct: newListUpdate },
    })

    const updatedShop = {
      ...shopInfo,
      products: newListUpdate,
    }

    dispatch(updateShopInMyShops(updatedShop))
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const deleteProduct = (shopDetail, pid, pIndex) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product

  try {
    await productApi.deleteShopProduct(shopDetail.id, pid)

    const updatedProducts = listProduct.filter((p) => !(p.id === pid && p.index === pIndex))

    const updatedShopDetail = {
      ...shopDetail,
      products: updatedProducts,
    }

    // Dispatch action with filtered products
    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: {
        listProduct: updatedProducts,
      },
    })

    // Update shop state
    dispatch(selectShopAndSelectShopDetail(updatedShopDetail))
    dispatch(updateShopInMyShops(updatedShopDetail))
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

// Action creator for copying a product
export const copyProduct = (shopDetail, pId, pIndex) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product
  const newListProduct = [...listProduct]

  const index = newListProduct.findIndex((p) => p.id === pId && p.index === pIndex)
  const newProduct = newListProduct[index]

  const convertData = {
    products: [
      {
        categoryId: null,
        items: [
          {
            photoUrls: newProduct.photoUrls,
            active: newProduct.active,
            name: newProduct.name,
            description: newProduct.description,
            dateSelectInfo: newProduct?.dateSelectInfo || null,
            price: newProduct.price,
            maxQuantity: newProduct.maxQuantity,
            minOrderQty: newProduct.minOrderQty,
            defaultQuantity: newProduct.defaultQuantity,
            limitPerOrder: newProduct.limitPerOrder,
            availableOfStock: newProduct.availableOfStock,
            inventory: newProduct.inventory,
            index: newProduct.index + 1,
            subItems: newProduct.subItems.length
              ? newProduct.subItems.map((subItem) => {
                  const { id, isOpenSubItems, ...restSubItem } = subItem
                  const cleanedSubItem = { ...restSubItem }

                  if (cleanedSubItem.list) {
                    cleanedSubItem.list = cleanedSubItem.list.map((option) => {
                      const { id: _id, ...restOption } = option
                      return restOption
                    })
                  }
                  return cleanedSubItem
                })
              : [],
          },
        ],
      },
    ],
  }

  try {
    const res = await productApi.createShopProduct(shopDetail.id, convertData)

    if (res) {
      // Create copied product first
      const copiedProduct = {
        ...res.msgResp[0].items[0],
        isOpenSubItems: false,
        isCopied: true, // Always set true for new copy
      }

      // Reset isCopied and add new product in one update
      const updatedListProduct = [
        copiedProduct,
        ...newListProduct.map((p) => ({ ...p, isCopied: false })),
      ]

      // Dispatch single update
      dispatch({
        type: COPY_PRODUCT,
        payload: { listProduct: updatedListProduct },
      })

      dispatch(
        selectShopAndSelectShopDetail({
          ...shopDetail,
          products: updatedListProduct,
        })
      )

      dispatch(
        updateShopInMyShops({
          ...shopDetail,
          products: updatedListProduct,
        })
      )

      setTimeout(() => {
        dispatch(clearProductFlash(copiedProduct.id))
      }, 2000)
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const addProduct = (shopDetail, data) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product

  // Đảm bảo list cũ có isCopied là boolean
  const updatedListProduct = listProduct.map((p) => ({
    ...p,
    isCopied: Boolean(p.isCopied),
  }))

  try {
    const { msgResp } = await productApi.createShopProduct(shopDetail.id, data)

    const newCreatedProduct = {
      ...msgResp[0].items[0],
      isOpenSubItems: false, // Set explicit boolean default
      isCopied: true, // Luôn set giá trị boolean
    }

    updatedListProduct.unshift(newCreatedProduct)

    dispatch({
      type: ADD_PRODUCT_SUCCESS,
      payload: { listProduct: updatedListProduct },
    })

    dispatch(
      selectShopAndSelectShopDetail({
        ...shopDetail,
        products: updatedListProduct,
      })
    )

    dispatch(
      updateShopInMyShops({
        ...shopDetail,
        products: updatedListProduct,
      })
    )

    setTimeout(() => {
      dispatch(clearProductFlash(msgResp[0].items[0].id))
    }, 2000)
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const swapProduct = (_product, direction) => (dispatch, getState) => {
  const { product, shop } = getState()
  const { listProduct } = product
  const { selectedShop } = shop
  const newListProduct = [...listProduct]

  const currentIndex = newListProduct.findIndex((item) => item.id === _product.id)

  // Validate swap direction
  if (direction === 'up' && currentIndex <= 0) return
  if (direction === 'down' && (currentIndex === -1 || currentIndex >= newListProduct.length - 1)) {
    return
  }

  // Calculate target index
  const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1

  // Swap items
  const temp = newListProduct[currentIndex]
  newListProduct[currentIndex] = newListProduct[targetIndex]
  newListProduct[targetIndex] = temp

  // Update indexes
  newListProduct[currentIndex].index = currentIndex
  newListProduct[targetIndex].index = targetIndex

  const actionType = direction === 'up' ? SWAP_UP_PRODUCT : SWAP_DOWN_PRODUCT

  dispatch({
    type: actionType,
    payload: { listProduct: newListProduct },
  })

  // Update shop state
  dispatch(
    selectShopAndSelectShopDetail({
      ...selectedShop,
      products: newListProduct,
    })
  )
  dispatch(updateShopInMyShops({ ...selectedShop, products: newListProduct }))
}

export const exportProductToLazada = (_product) => (dispatch, getState) => {
  try {
    const { product } = getState()
    const { listProductLazada, listProduct } = product
    const newListProductLazada = [...listProductLazada]
    const newListProduct = [...listProduct]
    const findIndex = newListProduct.findIndex((item) => item.id === _product.id)

    if (_product.lazadaProductId) throw new Error('Product already in list')

    const newProduct = {
      ..._product,
      variations: _product.subItems || [],
      isPendingToPush: true,
      isOpenModalEdit: true,
      availableOfStock: 0,
      purchaseCount: 0,
      quantity: _product.subItems.length > 0 ? 0 : 1000,
      description: 'Example Description',
      lazadaProductId: _product.id,
    }

    const { subItems, ...restProduct } = newProduct

    newListProductLazada.unshift(restProduct)

    if (findIndex !== -1) {
      newListProduct[findIndex].lazadaProductId = _product.id
    }

    dispatch({
      type: EXPORT_PRODUCT_TO_LAZADA,
      payload: {
        listProductLazada: newListProductLazada,
        listProduct: newListProduct,
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }
}

export const pushToLazada = (data) => async (dispatch, getState) => {
  try {
    const { product, shop } = getState()
    const { selectedShop } = shop
    const { listProductLazada, listProduct } = product
    const newListProductLazada = [...listProductLazada]
    const newListProduct = [...listProduct]

    if (data.variations?.length && !data.listVariationProduct) {
      throw new Error('Please generate to list variant or remove variant')
    }

    const { msgResp } = await lazadaApi.createProductLazada(data)

    const convertedMsgResp = { ...msgResp, availableOfStock: msgResp.quantity }

    newListProductLazada.unshift(convertedMsgResp)

    const newList = newListProductLazada
      .filter((item) => item.item_id)
      .map((item) => ({
        ...item,
        isPendingToPush: false,
        isOpenModalEdit: false,
      }))

    const updateListProduct = newListProduct.map((item) => {
      if (item.name === msgResp.name) {
        return {
          ...item,
          lazadaProductId: msgResp.id,
          item_id: msgResp.item_id,
        }
      }

      return item
    })

    dispatch(selectShopAndSelectShopDetail({ ...selectedShop, products: updateListProduct }))

    dispatch({
      type: PUSH_TO_LAZADA,
      payload: { listProductLazada: newList, listProduct: updateListProduct },
    })

    return msgResp
  } catch (error) {
    throw new Error(error?.msgResp?.message || error?.msgResp || error.message)
  }
}

export const updateListProductLazada = (newList) => (dispatch) => {
  dispatch({
    type: UPDATE_LIST_PRODUCT_LAZADA,
    payload: { listProductLazada: newList },
  })
}

export const deleteProductLazada = (shop, lazProductId) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProductLazada, listProduct } = product
  const newListProductLazada = [...listProductLazada]
  const newListProduct = [...listProduct]

  const indexProduct = newListProduct.findIndex((item) => item.lazadaProductId === lazProductId)
  const indexProductLazada = newListProductLazada.findIndex((item) => item.id === lazProductId)

  const updateProductLists = () => {
    if (newListProduct[indexProduct]?.lazadaProductId) {
      newListProduct[indexProduct].lazadaProductId = null
    }
    if (newListProduct[indexProduct]?.item_id) {
      newListProduct[indexProduct].item_id = null
    }

    dispatch({
      type: DELETE_PRODUCT_LAZADA,
      payload: {
        listProductLazada: newListProductLazada.filter((item) => item.id !== lazProductId),
        listProduct: newListProduct,
      },
    })
    dispatch(selectShopAndSelectShopDetail({ ...shop, products: newListProduct }))
  }

  if (indexProductLazada !== -1) {
    if (newListProductLazada[indexProductLazada].isPendingToPush) {
      updateProductLists()
    } else {
      try {
        await lazadaApi.removeProduct(shop?.id, lazProductId)
        updateProductLists()
      } catch (e) {
        throw new Error(e.message || e.msgResp)
      }
    }
  }
}

export const setOpenSubItem = (value, pId, pIndex) => (dispatch, getState) => {
  const { product, shop } = getState()
  const { listProduct } = product
  const { selectedShop } = shop
  const newListProduct = [...listProduct]
  const index = newListProduct.findIndex((item) => item.id === pId && item.index === pIndex)

  // Ensure boolean value
  newListProduct[index].isOpenSubItems = Boolean(value)

  dispatch({
    type: TOGGLE_SUB_ITEM,
    payload: {
      listProduct: newListProduct,
    },
  })

  dispatch(selectShopAndSelectShopDetail({ ...selectedShop, products: newListProduct }))
  dispatch(updateShopInMyShops({ ...selectedShop, products: newListProduct }))
}

export const toggleSubItem = (pId, pIndex) => (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product
  const newListProduct = listProduct.map((item) => {
    if (item.id === pId && item.index === pIndex) {
      return {
        ...item,
        isOpenSubItems: !item.isOpenSubItems, // Explicit boolean conversion
      }
    }
    return item
  })

  dispatch({
    type: TOGGLE_SUB_ITEM,
    payload: {
      listProduct: newListProduct,
    },
  })
}

export const setOpenVariationsForProduct = (checked, pid) => (dispatch, getState) => {
  const { product } = getState()
  const { listProductLazada } = product

  const newListProductLazada = [...listProductLazada]

  const updatedList = newListProductLazada.map((item) => {
    if (item.id === pid) {
      return {
        ...item,
        isOpenVariations: checked,
      }
    }

    return item
  })

  dispatch({
    type: TOGGLE_VARIATIONS,
    payload: { listProductLazada: updatedList },
  })
}

export const getLazadaCategorySuggestions = (shopId, pName) => async (dispatch) => {
  try {
    const postData = {
      shopId,
      productName: pName,
    }

    const { msgResp } = await lazadaApi.getLazadaCategorySuggestions(postData)

    dispatch({
      type: GET_LAZADA_CATEGORY_SUGGESTIONS,
      payload: { listLazadaCategorySuggestions: msgResp },
    })
  } catch (error) {
    const errorMessage = error?.msgResp || error.message || 'Something went wrong'
    dispatch({
      type: GET_LAZADA_CATEGORY_SUGGESTIONS,
      payload: { errorMessageCategory: errorMessage },
    })
  }
}

export const clearListCategorySuggestions = () => (dispatch) => {
  dispatch({
    type: CLEAR_LAZADA_CATEGORY_SUGGESTIONS,
    payload: { listLazadaCategorySuggestions: [] },
  })
}

export const getBrandByPages = (data) => async (dispatch) => {
  try {
    const { msgResp } = await lazadaApi.getBrandByPages(data)
    dispatch({
      type: GET_BRAND_BY_PAGES,
      payload: {
        brandByPages: msgResp?.module,
      },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const updateNewListVariantWithActionRemove =
  (prodSelected, newListVariation) => (dispatch, getState) => {
    const { product } = getState()
    const { listProductLazada } = product

    const updatedList = listProductLazada.map((item) => {
      if (item.id === prodSelected.id) {
        return {
          ...item,
          listVariationProduct: newListVariation,
        }
      }

      return item
    })

    dispatch({
      type: UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE,
      payload: {
        listProductLazada: updatedList,
      },
    })
  }

const ensureIsCopiedBoolean = (products) =>
  products.map((product) => ({
    ...product,
    isCopied: product.isCopied || false, // Nếu undefined thì set false
  }))

// Handle COPY_PRODUCT_SUCCESS in the reducer
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_VARIATION_PRODUCT_FAILED:
    case UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE:
    case UPDATE_LIST_PRODUCT_LAZADA:
    case UPDATE_NEW_LIST_PRODUCT_LAZADA:
    case HANDLE_UPDATE_VARIATION:
    case GET_BRAND_BY_PAGES:
    case FETCH_LIST_VARIATION_PRODUCT:
    case PUSH_TO_LAZADA:
    case HANDLE_ADD_VARIATION:
    case UPDATE_SINGLE_PRODUCT_LAZADA:
    case SELECT_PRODUCT_TO_PUSH_LAZADA:
    case FETCH_LIST_PRODUCT_LAZADA:
    case UPDATE_EFFECT:
    case TOGGLE_VARIATIONS:
    case EXPORT_PRODUCT_TO_LAZADA:
    case CLEAR_SELECT_PRODUCT_LAZADA:
    case CLEAR_LAZADA_CATEGORY_SUGGESTIONS:
    case GET_LAZADA_CATEGORY_SUGGESTIONS:
    case TOGGLE_ACTIVE_PRODUCT:
    case UPLOAD_IMAGE_PRODUCT:
    case TOGGLE_SUB_ITEM:
    case SELECT_TAB:
    case DELETE_PRODUCT_LAZADA:
    case SELECT_PRODUCT_LAZADA:
    case UPLOAD_IMAGE_PRODUCT_LAZADA:
    case PUSH_TO_LAZADA_SUCCESS:
    case PUSH_TO_LAZADA_FAILED:
      return {
        ...state,
        ...action.payload,
      }
    case CLEAR_PRODUCT_FLASH:
      return {
        ...state,
        listProduct: state.listProduct.map((product) =>
          product.id === action.payload.productId
            ? { ...product, isCopied: false }
            : { ...product, isCopied: product.isCopied || false }
        ),
      }
    case COPY_PRODUCT_SUCCESS:
      return {
        ...state,
        listProduct: [action.payload, ...state.listProduct],
        // Optionally, set a timeout to remove the isCopied flag after some time
        // This can be handled in middleware or a separate action if needed
      }
    case FETCH_LIST_PRODUCT:
    case ADD_PRODUCT_SUCCESS:
    case COPY_PRODUCT:
    case UPDATE_PRODUCT_SUCCESS:
    case UPDATE_NEW_LIST_PRODUCT:
      return {
        ...state,
        listProduct: ensureIsCopiedBoolean(action.payload.listProduct),
      }
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        listProduct: action.payload.listProduct,
      }
    case SWAP_UP_PRODUCT:
    case SWAP_DOWN_PRODUCT:
      return {
        ...state,
        listProduct: action.payload.listProduct.map((product) => ({
          ...product,
          isCopied: Boolean(product.isCopied),
        })),
      }
    default:
      return state
  }
}

export default productReducer
