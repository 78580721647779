import eventApi from '_api/event'
import CustomSwalFire from '_components/CustomSwalFire'
import { deleteEvent, getEventInShop } from '_redux/modules/event'
import { getEventAndOrder } from '_redux/modules/orderEvent'
import { PACKAGE_BASIC } from '_utils/constant'
import { flexibleSearch } from '_utils/function'
import { handleDataOrderEvent } from '_utils/functions/handler'
import { getCreateEventData, getUserInfo, removeCreateEventData } from '_utils/localData'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import { buildEncryptedDataURL } from '../utils/function'
import { useIsMobile } from './isMobile'

const PageSize = 10

export function useEvent() {
  const dispatch = useDispatch()
  const userInfo = getUserInfo()
  const history = useHistory()
  const isMobile = useIsMobile()

  const { addToast } = useToasts()

  const { selectedShop } = useSelector((state) => state.shop)
  const { eventsByShop, eventsByShopFiltered } = useSelector((state) => state.event)
  const { currentPackage } = useSelector((state) => state.subscription)

  const [view, setView] = useState('grid')
  const [loading, setLoading] = useState(true)

  const [openBroadcastModal, setOpenBroadcastModal] = useState(false)
  const [message, setMessage] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')

  // for broadcast message function
  const [eventSelected, setEventSelected] = useState(null)
  const { eventAndOrderById } = useSelector((state) => state.orderEvent)
  const listOrderEvent =
    eventAndOrderById.find((event) => event.id === eventSelected?.id)?.orders || []
  const { listOrderEventHandled = [] } = handleDataOrderEvent(
    listOrderEvent,
    eventSelected?.adminCost || 0,
    eventSelected?.discount || 0,
    eventSelected?.deliveryCost || 0
  )

  const listEventByShop = !isEmpty(eventsByShop) ? eventsByShop[selectedShop?.id] || [] : []

  const currentPackagePro =
    currentPackage !== (undefined || null) &&
    currentPackage.subscriptionPackageType !== PACKAGE_BASIC

  useEffect(() => {
    const eventCreateData = getCreateEventData()

    if (eventCreateData) {
      removeCreateEventData()
    }
  }, [])

  useEffect(() => {
    if (!openBroadcastModal) {
      setMessage('')
      setEventSelected(null)
    }
  }, [openBroadcastModal])

  useEffect(() => {
    let isMounted = true

    const eventView = localStorage.getItem('EVENT_VIEW')
    if (eventView && !isMobile) {
      setView(eventView)
    } else {
      setView(isMobile ? 'list' : 'grid')
    }

    const fetchData = async () => {
      await dispatch(getEventInShop(selectedShop?.id))
      if (isMounted) {
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [selectedShop?.id, isMobile])

  const handleViewChange = (newView) => {
    localStorage.setItem('EVENT_VIEW', newView)
    setView(newView)
  }

  const listEventByShopFiltered = useMemo(() => {
    if (!search) return eventsByShopFiltered

    return eventsByShopFiltered.filter((event) => flexibleSearch(event.title, search))
  }, [search, JSON.stringify(eventsByShopFiltered)])

  let currentEvents = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return listEventByShopFiltered.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(listEventByShopFiltered)])

  const isExistInCurrentEvents = listEventByShopFiltered?.some((event) =>
    currentEvents.some((currentEvent) => event.id === currentEvent.id)
  )

  if (search.length >= 1 && !isExistInCurrentEvents) {
    currentEvents = listEventByShopFiltered

    if (currentEvents.length >= PageSize && listEventByShopFiltered?.length >= PageSize) {
      setCurrentPage(1)
    }
  }

  const toggleBroadcastModal = (eSelected) => {
    setEventSelected(eSelected)
    dispatch(getEventAndOrder(eSelected.id))
    setOpenBroadcastModal(!openBroadcastModal)
  }

  const onChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const onCreateNotification = async () => {
    if (listOrderEventHandled.length > 0) {
      try {
        await Promise.all(
          listOrderEventHandled.map((order) =>
            eventApi.broadcastMessage({
              receiverId: order.uid,
              message,
              title: `Broadcast from [${order?.eTitle}]`,
            })
          )
        )

        setMessage('')
        setOpenBroadcastModal(null)
        addToast('Notification created successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (error) {
        addToast(error.msgResp || error.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }

  const onDirectCreateEvent = () => {
    if (userInfo?.id) {
      const url = buildEncryptedDataURL(userInfo, selectedShop, '/event/create')
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  const onReopenEvent = (event) => {
    localStorage.setItem('EVENT_ID_REOPEN', event.id)
    history.push(`/admin-console/${selectedShop?.shopSlug}/event/create`)
  }

  const onDeleteEvent = (selectedEvent) => {
    CustomSwalFire.delete({
      title: 'Are you sure?',
      text: "You won't be able to view orders again and this action cannot be undone!",
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      onConfirm: async () => {
        try {
          await dispatch(deleteEvent(selectedEvent.id, selectedShop?.id))
          addToast('Event deleted successfully', {
            appearance: 'success',
            autoDismiss: true,
          })
        } catch (error) {
          addToast(error.msgResp || error.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      },
      onError: (error) => {
        CustomSwalFire.error({
          title: 'Error!',
          text: error.message,
        })
      },
    })
  }

  return {
    view,
    handleViewChange,
    currentEvents,
    currentPage,
    setCurrentPage,
    listEventByShopFiltered,
    openBroadcastModal,
    setOpenBroadcastModal,
    message,
    onChangeMessage,
    onCreateNotification,
    search,
    setSearch,
    toggleBroadcastModal,
    PageSize,
    currentPackagePro,
    onReopenEvent,
    loading,
    listEventByShop,
    onDirectCreateEvent,
    onDeleteEvent,
    eventSelected,
    listOrderEventHandled,
  }
}
